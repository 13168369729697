import {
  Card,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import Custom3dotString from "../../../../components/Custom3dotString";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AppColors } from "../../../../util/AppColors";
import CustomTextField from "../../../../components/CustomTextField";
import { useState } from "react";

const chatList = [
  {
    name: "Vivek",
    lastMessage: "What is the pets issue?",
    date: new Date(new Date().setDate(new Date().getDate() - 2)),
    profileImage: "https://picsum.photos/200",
  },
  {
    name: "Ramesh",
    lastMessage: "Drop provided?",
    date: new Date(new Date().setDate(new Date().getDate() - 3)),
    profileImage: "https://picsum.photos/201",
  },
  {
    name: "Karthik",
    lastMessage: "Please come to clinic",
    date: new Date(new Date().setDate(new Date().getDate() - 1)),
    profileImage: "https://picsum.photos/202",
  },
];

const chats = [
  {
    isDoctorMsg: true,
    msg: "Hey Bruno!",
    time: new Date(),
  },
  {
    isDoctorMsg: false,
    msg: "Bruno not eating for two days",
    time: new Date(),
  },
  {
    isDoctorMsg: true,
    msg: "Oh okay, he can take water?",
    time: new Date(),
  },
  {
    isDoctorMsg: false,
    msg: "Water taking but, very less amount",
    time: new Date(),
  },
  {
    isDoctorMsg: true,
    msg: "Did you do outing for him?",
    time: new Date(),
  },
  {
    isDoctorMsg: false,
    msg: "Yes, two days befor went for park",
    time: new Date(),
  },
  {
    isDoctorMsg: true,
    msg: "Okay admit Bruno, as soon as possible",
    time: new Date(),
  },
];

const Chat = () => {
  const [msg, setMsg] = useState("");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <Card
          sx={{
            borderColor: "#79C5EF61",
            padding: 2,
          }}
        >
          <Typography className="balck8 font-bold fs18">Chat</Typography>
          {chatList?.map((cl, i) => (
            <div key={i}>
              <div className="flex-row-ali-cen mv15">
                <img src={cl?.profileImage} alt="" className="chatImg" />
                <div className="flex-column ml10">
                  <Typography className="fs14 balck8 txt-semi-bold capitalize">
                    Dr. {cl?.name}
                  </Typography>
                  <Custom3dotString
                    str={cl?.lastMessage}
                    className="txt-regular fs14 balck8"
                    length={25}
                  />
                </div>
                <div className="flex1-end">
                  <Typography className="gray13 txt-regular fs10">
                    {moment(cl?.date).format("dddd, hh:mma")}
                  </Typography>
                </div>
              </div>
              {chatList?.length !== i + 1 && <div className="box-hor-split" />}
            </div>
          ))}
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
        <Card
          sx={{
            borderColor: "#79C5EF61",
            padding: 2,
            minHeight: 400,
          }}
        >
          <div className="flex-row-ali-cen">
            <img
              className="chatLarImg"
              alt=""
              src="https://picsum.photos/205"
            />
            <div className="flex-column ml15">
              <Typography className="txt-semi-bold fs26 black8">
                Dr Rahul
              </Typography>
              <Typography className="txt-regular fs16 black8">
                Online - Last seen, 2.02pm
              </Typography>
            </div>
            <div className="flex1-end">
              <MoreVertIcon sx={{ color: AppColors.gray5 }} />
            </div>
          </div>
          <div className="box-hor-split mv10" />

          <div className="chtScroll">
            {chats?.map((ct, i) => (
              <div key={i} className="mv10">
                <div
                  className={`flex-row-ali-cen ${
                    ct?.isDoctorMsg ? "flex1-end" : "flex-start"
                  }`}
                >
                  {!ct?.isDoctorMsg && <div className="grayDot mr10" />}
                  <Typography
                    className={ct?.isDoctorMsg ? "msgVet" : "msgUser"}
                  >
                    {ct?.msg}
                  </Typography>
                  {ct?.isDoctorMsg && <div className="blueDot ml10" />}
                </div>
                <Typography
                  className={`fs12 gray14 txt-regular mt5 ${
                    ct?.isDoctorMsg ? "vetMsgTime" : "usrMsgTime"
                  }`}
                >
                  {moment(ct?.time).format("dddd, h.mma")}
                </Typography>
              </div>
            ))}
          </div>
          <div className="flex-row-ali-cen mv10">
            <div className="w90Per">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  background: AppColors.lightBlue,
                  borderRadius: 20,
                  height: 40,
                }}
              >
                <IconButton sx={{ p: "5px" }} aria-label="menu">
                  <img
                    src={require("../../../../assets/images/png/attach.png")}
                    alt=""
                    className="imghw30"
                  />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Type your message here..."
                  inputProps={{ "aria-label": "Type your message here..." }}
                  value={msg}
                  onChange={(e) => setMsg(e?.target?.value)}
                />
                <IconButton type="button" aria-label="search">
                  <img
                    src={require("../../../../assets/images/png/emoji.png")}
                    alt=""
                    className="imghw30"
                  />
                </IconButton>

                <IconButton color="primary" aria-label="directions">
                  <img
                    src={require("../../../../assets/images/png/micBlack.png")}
                    alt=""
                    className="img-wh25"
                  />
                </IconButton>
              </Paper>
            </div>
            <div className="w10Per flex-end">
              <div className="sndBtnBack cursor">
                <img
                  src={require("../../../../assets/images/png/sendIcon.png")}
                  alt=""
                  className="img-wh25"
                />
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Chat;
