import { Typography } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import { getClinicInternalTags } from "../../../../redux/reducers/clinicSlice";
import {
  getPetDetailsById,
  getPetsCompliantSummary,
  updateInternalTagsByPetId,
} from "../../../../redux/reducers/petSlice";
import { AppColors } from "../../../../util/AppColors";

const InternalTags = ({
  intrnalTagModVsble,
  setIntrnalTagModVsble,
  updatedApp,
  appoinment,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [color, setColor] = useState(AppColors.appPrimary);
  const [tags, setTags] = useState([]);
  const tagList = useSelector((state) => state?.clinic?.internaltags);
  const [filteredTags, setFilteredTags] = useState([]);
  const petDetails = useSelector((state) => state?.pet?.petDetails);
  const compPetDet = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.pet
  );
  const apntId = appoinment?.appoinment?.appoimentId;
  const petData = apntId ? compPetDet : petDetails;
  const petId = appoinment?.appoinment?.petId;

  useEffect(() => {
    dispatch(getClinicInternalTags());
  }, []);

  useEffect(() => {
    const reqList = petData?.petInternalTags?.map((it, i) => ({
      ...it,
      id: i,
      tags: it?.tags,
      color: it?.color,
    }));
    setTags(reqList);
  }, [petData]);

  useEffect(() => {
    const reqTags = tagList
      ?.map((ft) => ({ ...ft, id: ft?.internalId }))
      ?.filter((tg) =>
        tg?.tags?.toLowerCase().includes(searchValue?.toLowerCase())
      );
    setFilteredTags(reqTags);
  }, [searchValue]);

  const handleModClose = () => {
    setIntrnalTagModVsble(false);
    setColor(AppColors.appPrimary);
    setSearchValue("");
    setTags([]);
    setColor(AppColors.appPrimary);
  };

  const updateInternalTags = () => {
    const data = {
      intertatags: tags?.map((tg) => ({ tags: tg?.tags, color: tg?.color })),
    };

    dispatch(
      updateInternalTagsByPetId({
        petId: apntId ? updatedApp?.petId : petId,
        data,
      })
    ).then((res) => {
      if (res?.payload) {
        setSearchValue("");
        setTags([]);
        setColor(AppColors.appPrimary);
        if (apntId) {
          dispatch(getPetsCompliantSummary(updatedApp?.appoimentId ?? apntId));
        } else {
          dispatch(getPetDetailsById(petId));
        }
        dispatch(getClinicInternalTags());
        setIntrnalTagModVsble(false);
      }
    });
  };

  const handleAddTag = (itm) => {
    if (itm) {
      const isPrevTag = tags?.find((tg) => tg?.id === itm?.id);
      setSearchValue("");
      if (isPrevTag) return;
      const reqList = [...tags, itm];
      setTags(reqList);
    } else {
      if (searchValue === "") return;
      setTags([
        ...tags,
        { tags: searchValue, color, id: Date.now(), newTag: true },
      ]);
      setSearchValue("");
    }
  };

  const handleRemoveTag = (itm) => {
    const reqList = tags?.filter((tg) => tg?.id !== itm?.id);
    setTags(reqList);
  };

  return (
    <CustomModal
      open={intrnalTagModVsble}
      onClose={handleModClose}
      header="Internal Tags"
      rightModal
      modalWidth={30}
    >
      <div container className="ph20">
        {tags?.length > 0 && (
          <div className="flex-row-wrap1">
            {tags?.map((tg, i) => (
              <div
                className="flex-row-ali-cen pv5 ph10 cursor br5 mh5 mv5"
                onClick={() => handleRemoveTag(tg)}
                style={{ background: tg?.color }}
                key={i}
              >
                <Typography className="font-bold fs10 white-color">
                  {tg?.tags}
                </Typography>
                <img
                  alt=""
                  src={require("../../../../assets/images/png/closeWhite.png")}
                  className="img-wh15 ml5"
                />
              </div>
            ))}
          </div>
        )}

        <div
          className={`flex-row-ali-cen w100Per ${tags?.length > 0 && "mt20"}`}
        >
          <div className="w25Per">
            <MuiColorInput format="hex" value={color} onChange={setColor} />
          </div>
          <div className="w60Per ph10">
            <CustomTextField
              placeholder="Search for tags"
              fullWidth
              handleChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
          <div className="w15Per">
            <CustomButton
              text="Add"
              smallBtn
              onClick={() => handleAddTag(null)}
            />
          </div>
        </div>

        {searchValue?.length > 0
          ? filteredTags?.length > 0 && (
              <div className="flex-row-wrap1 mt10">
                {filteredTags?.map((tg, i) => (
                  <div
                    className="flex-row-ali-cen pv5 ph10 cursor br5 mh5 mv5"
                    onClick={() => handleAddTag(tg)}
                    style={{ background: tg?.color }}
                    key={i}
                  >
                    <Typography className="font-bold fs10 white-color">
                      {tg?.tags}
                    </Typography>
                  </div>
                ))}
              </div>
            )
          : null}

        <div className="flex1-end mt50">
          <div className="w15Per">
            <CustomButton text="Save" smallBtn onClick={updateInternalTags} />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default InternalTags;
