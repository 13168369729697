
// live
export const notes = 'https://soap.vetinstant.com/soap_note/';
export const baseURL= "https://api.vetinstant.com/api/v2";

// // dev
// export const notes = 'https://soap.vetinstant.com/soap_note';
// export const baseURL = "http://137.184.130.244:1993/api/v2";

// // local
// export const notes = 'https://soap.vetinstant.com/soap_note';
// export const baseURL = "http://localhost:1993/api/v2";
 