export const breedList = [
  { id: 1, name: "Affenpinscher", value: "Affenpinscher" },
  { id: 2, name: "Afghan Hound", value: "Afghan Hound" },
  { id: 3, name: "Airedale Terrier", value: "Airedale Terrier" },
  { id: 4, name: "Akbash Dog", value: "Akbash Dog" },
  { id: 5, name: "Akita", value: "Akita" },
  {
    id: 6,
    name: "Alapaha Blue Blood Bulldog",
    value: "Alapaha Blue Blood Bulldog",
  },
  { id: 7, name: "Alaskan Husky", value: "Alaskan Husky" },
  { id: 8, name: "Alaskan Malamute", value: "Alaskan Malamute" },
  { id: 9, name: "American Bulldog", value: "American Bulldog" },
  { id: 10, name: "American Eskimo", value: "American Eskimo" },
  { id: 11, name: "American Foxhound", value: "American Foxhound" },
  {
    id: 12,
    name: "American Pit Bull Terrier",
    value: "American Pit Bull Terrier",
  },
  {
    id: 13,
    name: "American Staffordshire Terrier",
    value: "American Staffordshire Terrier",
  },
  { id: 14, name: "American Water Spaniel", value: "American Water Spaniel" },
  { id: 15, name: "Anatolian Shepherd Dog", value: "Anatolian Shepherd Dog" },
  { id: 16, name: "Aussiedoodle", value: "Aussiedoodle" },
  { id: 17, name: "Australian Cattle Dog", value: "Australian Cattle Dog" },
  { id: 18, name: "Australian Kelpie", value: "Australian Kelpie" },
  { id: 19, name: "Australian Shepherd", value: "Australian Shepherd" },
  { id: 20, name: "Australian Terrier", value: "Australian Terrier" },
  { id: 21, name: "Azawakh", value: "Azawakh" },
  { id: 22, name: "Bakharwal dog", value: "Bakharwal dog" },
  { id: 23, name: "Barbet", value: "Barbet" },
  { id: 24, name: "Basador", value: "Basador" },
  { id: 25, name: "Basenji", value: "Basenji" },
  {
    id: 26,
    name: "Basset Bleu de Gascogne",
    value: "Basset Bleu de Gascogne",
  },
  {
    id: 27,
    name: "Basset Fauve de Bretagne",
    value: "Basset Fauve de Bretagne",
  },
  { id: 28, name: "Basset Hound", value: "Basset Hound" },
  { id: 29, name: "Beagle", value: "Beagle" },
  { id: 30, name: "Bearded Collie", value: "Bearded Collie" },
  { id: 31, name: "Beauceron", value: "Beauceron" },
  { id: 32, name: "Bedlington Terrier", value: "Bedlington Terrier" },
  { id: 33, name: "Belgian Laekenois", value: "Belgian Laekenois" },
  { id: 34, name: "Belgian Malinois", value: "Belgian Malinois" },
  { id: 35, name: "Belgian Sheepdog", value: "Belgian Sheepdog" },
  { id: 36, name: "Belgian Tervuren", value: "Belgian Tervuren" },
  { id: 37, name: "Bergamasco", value: "Bergamasco" },
  { id: 38, name: "Berger Picard", value: "Berger Picard" },
  { id: 39, name: "Bernese Mountain Dog", value: "Bernese Mountain Dog" },
  { id: 40, name: "Bichon Frise", value: "Bichon Frise" },
  {
    id: 41,
    name: "Black and Tan Coonhound",
    value: "Black and Tan Coonhound",
  },
  { id: 42, name: "Black Russian Terrier", value: "Black Russian Terrier" },
  { id: 43, name: "Bloodhound", value: "Bloodhound" },
  { id: 44, name: "Blue Picardy Spaniel", value: "Blue Picardy Spaniel" },
  { id: 45, name: "Bluetick Coonhound", value: "Bluetick Coonhound" },
  { id: 46, name: "Boerboel", value: "Boerboel" },
  { id: 47, name: "Bolognese", value: "Bolognese" },
  { id: 48, name: "Border Collie", value: "Border Collie" },
  { id: 49, name: "Border Terrier", value: "Border Terrier" },
  { id: 50, name: "Borzoi", value: "Borzoi" },
  { id: 51, name: "Boston Terrier", value: "Boston Terrier" },
  { id: 52, name: "Bouvier des Flandres", value: "Bouvier des Flandres" },
  { id: 53, name: "Boxer", value: "Boxer" },
  { id: 54, name: "Boykin Spaniel", value: "Boykin Spaniel" },
  { id: 55, name: "Bracco Italiano", value: "Bracco Italiano" },
  { id: 56, name: "Braque D’Auvergne", value: "Braque D’Auvergne" },
  { id: 57, name: "Braque du Bourbonnais", value: "Braque du Bourbonnais" },
  {
    id: 58,
    name: "Braque Francais Pyrenean",
    value: "Braque Francais Pyrenean",
  },
  { id: 59, name: "Briard", value: "Briard" },
  { id: 60, name: "Brittany", value: "Brittany" },
  { id: 61, name: "Brussels Griffon", value: "Brussels Griffon" },
  { id: 62, name: "Bull Terrier", value: "Bull Terrier" },
  { id: 63, name: "Bulldog", value: "Bulldog" },
  { id: 64, name: "Bullmastiff", value: "Bullmastiff" },
  { id: 65, name: "Bully Kutta", value: "Bully Kutta" },
  { id: 66, name: "Cairn Terrier", value: "Cairn Terrier" },
  { id: 67, name: "Canaan Dog", value: "Canaan Dog" },
  { id: 68, name: "Canadian Eskimo Dog", value: "Canadian Eskimo Dog" },
  { id: 69, name: "Cane Corso", value: "Cane Corso" },
  { id: 70, name: "Cardigan Welsh Corgi", value: "Cardigan Welsh Corgi" },
  { id: 71, name: "Catahoula Leopard Dog", value: "Catahoula Leopard Dog" },
  { id: 72, name: "Catalan Sheepdog", value: "Catalan Sheepdog" },
  { id: 73, name: "Caucasian Ovcharka", value: "Caucasian Ovcharka" },
  {
    id: 74,
    name: "Cavalier King Charles Spaniel",
    value: "Cavalier King Charles Spaniel",
  },
  { id: 75, name: "Cavapom", value: "Cavapom" },
  { id: 76, name: "Cavapoo", value: "Cavapoo" },
  { id: 77, name: "Cesky Terrier", value: "Cesky Terrier" },
  { id: 78, name: "Chart Polski", value: "Chart Polski" },
  {
    id: 79,
    name: "Chesapeake Bay Retriever",
    value: "Chesapeake Bay Retriever",
  },
  { id: 80, name: "Chihuahua", value: "Chihuahua" },
  { id: 81, name: "Chinese Crested", value: "Chinese Crested" },
  { id: 82, name: "Chinese Shar-Pei", value: "Chinese Shar-Pei" },
  { id: 83, name: "Chinook", value: "Chinook" },
  { id: 84, name: "Chippiparai", value: "Chippiparai" },
  { id: 85, name: "Chow Chow", value: "Chow Chow" },
  { id: 86, name: "Chug", value: "Chug" },
  { id: 87, name: `Cirneco dell'Etna`, value: `Cirneco dell'Etna` },
  { id: 88, name: "Clumber Spaniel", value: "Clumber Spaniel" },
  { id: 89, name: "Cockapoo", value: "Cockapoo" },
  { id: 90, name: "Cocker Spaniel", value: "Cocker Spaniel" },
  { id: 91, name: "Collie", value: "Collie" },
  { id: 92, name: "Coton de Tulear", value: "Coton de Tulear" },
  { id: 93, name: "Curly-Coated Retriever", value: "Curly-Coated Retriever" },
  { id: 94, name: "Dachshund", value: "Dachshund" },
  { id: 95, name: "Dalmatian", value: "Dalmatian" },
  { id: 96, name: "Dandie Dinmont Terrier", value: "Dandie Dinmont Terrier" },
  { id: 97, name: "Doberman Pinscher", value: "Doberman Pinscher" },
  { id: 98, name: "Dogo Argentino", value: "Dogo Argentino" },
  { id: 99, name: "Dogue de Bordeaux", value: "Dogue de Bordeaux" },
  { id: 100, name: "Doxiepoo", value: "Doxiepoo" },
  { id: 101, name: "English Cocker Spaniel", value: "English Cocker Spaniel" },
  { id: 102, name: "English Foxhound", value: "English Foxhound" },
  { id: 103, name: "English Setter", value: "English Setter" },
  {
    id: 104,
    name: "English Springer Spaniel",
    value: "English Springer Spaniel",
  },
  { id: 105, name: "English Toy Spaniel", value: "English Toy Spaniel" },
  {
    id: 106,
    name: "Entlebucher Mountain Dog",
    value: "Entlebucher Mountain Dog",
  },
  { id: 107, name: "Estrela Mountain Dog", value: "Estrela Mountain Dog" },
  { id: 108, name: "Eurasier", value: "Eurasier" },
  { id: 109, name: "Field Spaniel", value: "Field Spaniel" },
  { id: 110, name: "Fila Brasileiro", value: "Fila Brasileiro" },
  { id: 111, name: "Finnish Lapphund", value: "Finnish Lapphund" },
  { id: 112, name: "Finnish Spitz", value: "Finnish Spitz" },
  { id: 113, name: "Flat-Coated Retriever", value: "Flat-Coated Retriever" },
  { id: 114, name: "Fox Terrier", value: "Fox Terrier" },
  { id: 115, name: "French Bulldog", value: "French Bulldog" },
  {
    id: 116,
    name: "German Longhaired Pointer",
    value: "German Longhaired Pointer",
  },
  { id: 117, name: "German Pinscher", value: "German Pinscher" },
  { id: 118, name: "German Shepherd Dog", value: "German Shepherd Dog" },
  {
    id: 119,
    name: "German Shorthaired Pointer",
    value: "German Shorthaired Pointer",
  },
  { id: 120, name: "German Spitz", value: "German Spitz" },
  {
    id: 121,
    name: "German Wirehaired Pointer",
    value: "German Wirehaired Pointer",
  },
  { id: 122, name: "Giant Schnauzer", value: "Giant Schnauzer" },
  { id: 123, name: "Glen of Imaal Terrier", value: "Glen of Imaal Terrier" },
  { id: 124, name: "Golden Retriever", value: "Golden Retriever" },
  { id: 125, name: "Goldendoodle", value: "Goldendoodle" },
  { id: 126, name: "Gordon Setter", value: "Gordon Setter" },
  {
    id: 127,
    name: "Grand Basset Griffon Vendéen",
    value: "Grand Basset Griffon Vendéen",
  },
  { id: 128, name: "Grand Bleu De Gascogne", value: "Grand Bleu De Gascogne" },
  { id: 129, name: "Great Dane", value: "Great Dane" },
  { id: 130, name: "Great Pyrenees", value: "Great Pyrenees" },
  {
    id: 131,
    name: "Greater Swiss Mountain Dog",
    value: "Greater Swiss Mountain Dog",
  },
  { id: 132, name: "Greenland Dog", value: "Greenland Dog" },
  { id: 133, name: "Greyhound", value: "Greyhound" },
  { id: 134, name: "Hamiltonstovare", value: "Hamiltonstovare" },
  { id: 135, name: "Harrier", value: "Harrier" },
  { id: 136, name: "Havanese", value: "Havanese" },
  { id: 137, name: "Hovawart", value: "Hovawart" },
  { id: 138, name: "Ibizan Hound", value: "Ibizan Hound" },
  { id: 139, name: "Icelandic Sheepdog", value: "Icelandic Sheepdog" },
  { id: 140, name: "Indian pariah dog", value: "Indian pariah dog" },
  { id: 141, name: "Indie", value: "Indie" },
  {
    id: 142,
    name: "Irish Red and White Setter",
    value: "Irish Red and White Setter",
  },
  { id: 143, name: "Irish Setter", value: "Irish Setter" },
  { id: 144, name: "Irish Terrier", value: "Irish Terrier" },
  { id: 145, name: "Irish Water Spaniel", value: "Irish Water Spaniel" },
  { id: 146, name: "Irish Wolfhound", value: "Irish Wolfhound" },
  { id: 147, name: "Italian Greyhound", value: "Italian Greyhound" },
  { id: 148, name: "Jack Russell Terrier", value: "Jack Russell Terrier" },
  { id: 149, name: "Japanese Chin", value: "Japanese Chin" },
  { id: 150, name: "Japanese Spitz", value: "Japanese Spitz" },
  { id: 151, name: "Jonangi", value: "Jonangi" },
  { id: 152, name: "Kanni", value: "Kanni" },
  { id: 153, name: "Keeshond", value: "Keeshond" },
  { id: 154, name: "Kerry Blue Terrier", value: "Kerry Blue Terrier" },
  { id: 155, name: "Kombai dog", value: "Kombai dog" },
  { id: 156, name: "Komondor", value: "Komondor" },
  { id: 157, name: "Kooikerhondje", value: "Kooikerhondje" },
  { id: 158, name: "Korean Jindo", value: "Korean Jindo" },
  { id: 159, name: "Kromfohrlander", value: "Kromfohrlander" },
  { id: 160, name: "Kuvasz", value: "Kuvasz" },
  { id: 161, name: "Labradoodle", value: "Labradoodle" },
  { id: 162, name: "Labrador Retriever", value: "Labrador Retriever" },
  { id: 163, name: "Lacy Dog", value: "Lacy Dog" },
  { id: 164, name: "Lagotto Romagnolo", value: "Lagotto Romagnolo" },
  { id: 165, name: "Lakeland Terrier", value: "Lakeland Terrier" },
  { id: 166, name: "Lancashire Heeler", value: "Lancashire Heeler" },
  { id: 167, name: "Large Munsterlander", value: "Large Munsterlander" },
  { id: 168, name: "Leonberger", value: "Leonberger" },
  { id: 169, name: "Lhasa Apso", value: "Lhasa Apso" },
  { id: 170, name: "Lhasapoo", value: "Lhasapoo" },
  { id: 171, name: "Longdog", value: "Longdog" },
  { id: 172, name: "Lowchen", value: "Lowchen" },
  { id: 173, name: "Lurcher", value: "Lurcher" },
  { id: 174, name: "Maltese", value: "Maltese" },
  { id: 175, name: "Maltipoo", value: "Maltipoo" },
  { id: 176, name: "Manchester Terrier", value: "Manchester Terrier" },
  {
    id: 177,
    name: "Manchester Terrier (Toy)",
    value: "Manchester Terrier (Toy)",
  },
  { id: 178, name: "Maremma Sheepdog", value: "Maremma Sheepdog" },
  { id: 179, name: "Mastiff", value: "Mastiff" },
  {
    id: 180,
    name: "Miniature American Shepherd",
    value: "Miniature American Shepherd",
  },
  { id: 181, name: "Miniature Bull Terrier", value: "Miniature Bull Terrier" },
  { id: 182, name: "Miniature Pinscher", value: "Miniature Pinscher" },
  { id: 183, name: "Miniature Schnauzer", value: "Miniature Schnauzer" },
  { id: 184, name: "Mudhol hound", value: "Mudhol hound" },
  { id: 185, name: "Mudi", value: "Mudi" },
  { id: 186, name: "Neapolitan Mastiff", value: "Neapolitan Mastiff" },
  { id: 187, name: "Newfoundland", value: "Newfoundland" },
  { id: 188, name: "Norfolk Terrier", value: "Norfolk Terrier" },
  { id: 189, name: "Norwegian Buhund", value: "Norwegian Buhund" },
  { id: 190, name: "Norwegian Elkhound", value: "Norwegian Elkhound" },
  { id: 191, name: "Norwegian Lundehund", value: "Norwegian Lundehund" },
  { id: 192, name: "Norwich Terrier", value: "Norwich Terrier" },
  {
    id: 193,
    name: "Nova Scotia Duck Tolling Retriever",
    value: "Nova Scotia Duck Tolling Retriever",
  },
  { id: 194, name: "Old English Sheepdog", value: "Old English Sheepdog" },
  { id: 195, name: "Others", value: "Others" },
  { id: 196, name: "Otterhound", value: "Otterhound" },
  { id: 197, name: "Papillon", value: "Papillon" },
  { id: 198, name: "Parson Russell Terrier", value: "Parson Russell Terrier" },
  { id: 199, name: "Pekeapoo", value: "Pekeapoo" },
  { id: 200, name: "Pekingese", value: "Pekingese" },
  { id: 201, name: "Pembroke Welsh Corgi", value: "Pembroke Welsh Corgi" },
  { id: 202, name: "Perro de Presa Canario", value: "Perro de Presa Canario" },
  { id: 203, name: "Peruvian Inca Orchid", value: "Peruvian Inca Orchid" },
  {
    id: 204,
    name: "Petit Basset Griffon Vendeen",
    value: "Petit Basset Griffon Vendeen",
  },
  { id: 205, name: "Pharaoh Hound", value: "Pharaoh Hound" },
  { id: 206, name: "Plott", value: "Plott" },
  { id: 207, name: "Pointer", value: "Pointer" },
  {
    id: 208,
    name: "Polish Lowland Sheepdog",
    value: "Polish Lowland Sheepdog",
  },
  { id: 209, name: "Pomapoo", value: "Pomapoo" },
  { id: 210, name: "Pomeranian", value: "Pomeranian" },
  { id: 211, name: "Pomsky", value: "Pomsky" },
  { id: 212, name: "Poodle", value: "Poodle" },
  { id: 213, name: "Portuguese Podengo", value: "Portuguese Podengo" },
  { id: 214, name: "Portuguese Pointer", value: "Portuguese Pointer" },
  { id: 215, name: "Portuguese Water Dog", value: "Portuguese Water Dog" },
  { id: 216, name: "Pug", value: "Pug" },
  { id: 217, name: "Pugapoo", value: "Pugapoo" },
  { id: 218, name: "Puggle", value: "Puggle" },
  { id: 219, name: "Puli", value: "Puli" },
  { id: 220, name: "Pumi", value: "Pumi" },
  { id: 221, name: "Pyrenean Mastiff", value: "Pyrenean Mastiff" },
  { id: 222, name: "Pyrenean Mountain Dog", value: "Pyrenean Mountain Dog" },
  { id: 223, name: "Pyrenean Shepherd", value: "Pyrenean Shepherd" },
  { id: 224, name: "Rajapalayam", value: "Rajapalayam" },
  { id: 225, name: "Rat Terrier", value: "Rat Terrier" },
  { id: 226, name: "Redbone Coonhound", value: "Redbone Coonhound" },
  { id: 227, name: "Rhodesian Ridgeback", value: "Rhodesian Ridgeback" },
  { id: 228, name: "Rottweiler", value: "Rottweiler" },
  { id: 229, name: "Russian Toy", value: "Russian Toy" },
  { id: 230, name: "Saint Bernard", value: "Saint Bernard" },
  { id: 231, name: "Saluki", value: "Saluki" },
  { id: 232, name: "Samoyed", value: "Samoyed" },
  { id: 233, name: "Schapendoes", value: "Schapendoes" },
  { id: 234, name: "Schipperke", value: "Schipperke" },
  { id: 235, name: "Schnoodle", value: "Schnoodle" },
  { id: 236, name: "Scottish Deerhound", value: "Scottish Deerhound" },
  { id: 237, name: "Scottish Terrier", value: "Scottish Terrier" },
  { id: 238, name: "Sealyham Terrier", value: "Sealyham Terrier" },
  { id: 239, name: "Segugio Italiano", value: "Segugio Italiano" },
  { id: 240, name: "Shetland Sheepdog", value: "Shetland Sheepdog" },
  { id: 241, name: "Shiba Inu", value: "Shiba Inu" },
  { id: 242, name: "Shih Tzu", value: "Shih Tzu" },
  { id: 243, name: "Shihpoo", value: "Shihpoo" },
  { id: 244, name: "Siberian Husky", value: "Siberian Husky" },
  { id: 245, name: "Silken Windhound", value: "Silken Windhound" },
  { id: 246, name: "Silky Terrier", value: "Silky Terrier" },
  { id: 247, name: "Skye Terrier", value: "Skye Terrier" },
  { id: 248, name: "Sloughi", value: "Sloughi" },
  {
    id: 249,
    name: "Slovakian Rough Haired Pointer",
    value: "Slovakian Rough Haired Pointer",
  },
  {
    id: 250,
    name: "Small Munsterlander Pointer",
    value: "Small Munsterlander Pointer",
  },
  {
    id: 251,
    name: "Soft Coated Wheaten Terrier",
    value: "Soft Coated Wheaten Terrier",
  },
  { id: 252, name: "Spanish Greyhound", value: "Spanish Greyhound" },
  { id: 253, name: "Spanish Water Dog", value: "Spanish Water Dog" },
  { id: 254, name: "Spinone Italiano", value: "Spinone Italiano" },
  { id: 255, name: "Sprollie", value: "Sprollie" },
  {
    id: 256,
    name: "Staffordshire Bull Terrier",
    value: "Staffordshire Bull Terrier",
  },
  { id: 257, name: "Standard Schnauzer", value: "Standard Schnauzer" },
  { id: 258, name: "Sussex Spaniel", value: "Sussex Spaniel" },
  { id: 259, name: "Swedish Lapphund", value: "Swedish Lapphund" },
  { id: 260, name: "Swedish Vallhund", value: "Swedish Vallhund" },
  { id: 261, name: "Thai Ridgeback", value: "Thai Ridgeback" },
  { id: 262, name: "Tibetan Mastiff", value: "Tibetan Mastiff" },
  { id: 263, name: "Tibetan Spaniel", value: "Tibetan Spaniel" },
  { id: 264, name: "Tibetan Terrier", value: "Tibetan Terrier" },
  { id: 265, name: "Tosa Ken", value: "Tosa Ken" },
  { id: 266, name: "Toy Fox Terrier", value: "Toy Fox Terrier" },
  { id: 267, name: "Toy Poodle", value: "Toy Poodle" },
  {
    id: 268,
    name: "Treeing Walker Coonhound",
    value: "Treeing Walker Coonhound",
  },
  { id: 269, name: "Vizsla", value: "Vizsla" },
  { id: 270, name: "Volpino Italiano", value: "Volpino Italiano" },
  { id: 271, name: "Weimaraner", value: "Weimaraner" },
  { id: 272, name: "Welsh Springer Spaniel", value: "Welsh Springer Spaniel" },
  { id: 273, name: "Welsh Terrier", value: "Welsh Terrier" },
  {
    id: 274,
    name: "West Highland White Terrier",
    value: "West Highland White Terrier",
  },
  { id: 275, name: "Whippet", value: "Whippet" },
  {
    id: 276,
    name: "Wirehaired Pointing Griffon",
    value: "Wirehaired Pointing Griffon",
  },
  { id: 277, name: "Wirehaired Vizsla", value: "Wirehaired Vizsla" },
  { id: 278, name: "Xoloitzcuintli", value: "Xoloitzcuintli" },
  { id: 279, name: "Yorkipoo", value: "Yorkipoo" },
  { id: 280, name: "Yorkshire Terrier", value: "Yorkshire Terrier" },
];

export const genderList = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
];

export const yesNoList = [
  { name: "Yes", value: "true" },
  { name: "No", value: "false" },
];

export const typeAppointemnt = [
  { name: "Phone", value: "Phone" },
  { name: "Walk-in", value: "Walk-in" },
];

export const typeService = [
  { name: "Consultation", value: "Consultation" },
  { name: "Grooming", value: "Grooming" },
  { name: "Pet Boarding", value: "Pet Boarding" },
];

export const serviceType = [
  { name: "Consultation", value: "Consultation" },
  { name: "Vaccination", value: "Vaccination" },
  { name: "Grooming", value: "Grooming" },
  { name: "Others", value: "Others" },
  { name: "Emergency", value: "Emergency" },
];

export const typeList = [
  { name: "Virtual", value: "Virtual" },
  { name: "Physical", value: "Physical" },
  // { name: "HomeVisit", value: "HomeVisit" },
];

export const typeListNew = [
  // { name: "Virtual", value: "Virtual" },
  { name: "Physical", value: "Physical" },
  // { name: "HomeVisit", value: "HomeVisit" },
];
export const clinicRolesList = [
  { name: "Veterinary Technician/Nurse", value: "TN" },
  { name: "Animal Caretaker/Attendant", value: "AC" },
  { name: "Vetinary Assistant", value: "AS" },
  { name: "Groomer", value: "GR" },
  { name: "Laboratory Technician", value: "LB" },
  { name: "Pharmacy Technician", value: "PH" },

  {
    name: "Practice Manager/Administrator",
    value: "PR",
  },
  { name: "Radiology Technician", value: "RD" },

  { name: "Receptionist", value: "RE" },
];

export const petTypeList = [{ name: "Dog", value: "dog" }];

export const salutationList = [
  { name: "Mr.", value: "Mr" },
  { name: "Ms.", value: "Ms" },
  { name: "Mrs.", value: "Mrs" },
  { name: "Dr.", value: "Dr" },
];

export const specialtyList = [
  { name: "Anesthesiology", value: "Anesthesiology" },
  { name: "Behavioral Medicine", value: "Behavioral Medicine" },
  { name: "Cardiology", value: "Cardiology" },
  { name: "Dentistry", value: "Dentistry" },
  { name: "Dermatology", value: "Dermatology" },
  { name: "Emergency and Critical Care", value: "Emergency and Critical Care" },
  {
    name: "Endocrinology and Metabolism",
    value: "Endocrinology and Metabolism",
  },
  { name: "Gastroenterology", value: "Gastroenterology" },
  { name: "Hematology/Immunology", value: "Hematology/Immunology" },
  { name: "Hepatology", value: "Hepatology" },
  { name: "Infectious Disease", value: "Infectious Disease" },
  { name: "Internal Medicine", value: "Internal Medicine" },
  { name: "Musculoskeletal", value: "Musculoskeletal" },
  { name: "Nephrology/Urology", value: "Nephrology/Urology" },
  { name: "Neurology", value: "Neurology" },
  { name: "Nutrition", value: "Nutrition" },
  { name: "Oncology", value: "Oncology" },
  { name: "Ophthalmology", value: "Ophthalmology" },
  { name: "Radiology", value: "Radiology" },
  { name: "Respiratory", value: "Respiratory" },
  { name: "Surgery", value: "Surgery" },
  { name: "Theriogenology", value: "Theriogenology" },
  { name: "Toxicology", value: "Toxicology" },
  { name: "Zoological Medicine", value: "Zoological Medicine" },
];

export const paymentSearchTypeList = [
  { name: "Vet Name", value: "vet-name" },
  { name: "Pet Name", value: "pet-name" },
];

export const appointmentSearchTypeList = [
  { name: "Pet Name", value: "pet-name" },
  { name: "User Name", value: "user-name" },
];

export const dateFilterList = [
  { name: "Today", value: "today" },
  { name: "This Week", value: "this" },
];

export const medicalHistorySearchTypelist = [
  { name: "Condition", value: "condition" },
  { name: "Date", value: "date_range" },
];

export const dateFilterTodayLastWeek = [
  { name: "Today", value: "today" },
  { name: "Last week", value: "last" },
];

export const vetDropList = [{ name: "Vet Name", value: "vet-name" }];

export const appointDropList = [
  { name: "Pet Name", value: "pet-name" },
  { name: "Vet Name", value: "vet-name" },
  { name: "Order Id", value: "order-id" },
];

export const appointDropListProduct = [
  { name: "Product Name", value: "product-name" },
  { name: "Vet Name", value: "vet-name" },
  { name: "Order Id", value: "order-id" },
];
export const locationDropList = [
  { name: "Noida", value: "noida" },
  { name: "Chennai", value: "chennai" },
  { name: "Delhi", value: "delhi" },
  { name: "Hydrabad", value: "hydrabad" },
  { name: "Mumbai", value: "mumbai" },
  { name: "Kolkata", value: "kolkata" },
  { name: "Raipur", value: "raipur" },
  { name: "Tamil Nadu", value: "tamil nadu" },
  { name: "Jaipur", value: "jaipur" },
];

export const NeuteredList = [
  { name: "Yes", value: "Y" },
  { name: "No", value: "N" },
];

export const PaymentTypeList = [
  { name: "Cash", value: "cash" },
  { name: "Card", value: "card" },
  { name: "Net Banking", value: "netBanking" },
  { name: "Upi", value: "upi" },
];

export const paymentFilters = [
  { name: "All", value: "all" },
  { name: "Paid", value: "Paid" },
  // { name: "Partially Paid", value: "Partially" },
  { name: "Unpaid", value: "Unpaid" },
];

export const taskCategory = [
  { name: "Diagnostics", value: "Diagnostics" },
  { name: "Preventive", value: "Preventive" },
  { name: "Medication", value: "Medication" },
  { name: "Others", value: "Others" },
];

export const yesNoBoolList = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];
