import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import { getClinicAllergies } from "../../../../redux/reducers/clinicSlice";
import {
  getPetDetailsById,
  getPetsCompliantSummary,
  updateAllergiesByPetId,
} from "../../../../redux/reducers/petSlice";

const Allergies = ({
  allergyModOpen,
  setAllergyModOpen,
  updatedApp,
  appointment,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [allergies, setAllergies] = useState([]);
  const [filteredAllergies, setFilteredAllergies] = useState([]);
  const allergyList = useSelector((state) => state?.clinic?.allergies);
  const comPeDet = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.pet
  );
  const petDetails = useSelector((state) => state?.pet?.petDetails);
  const apntId = appointment?.appoinment?.appoimentId;
  const petData = apntId ? comPeDet : petDetails;
  const petId = appointment?.appoinment?.petId;

  useEffect(() => {
    dispatch(getClinicAllergies());
  }, []);

  useEffect(() => {
    const reqList = petData?.allergies?.map((al, i) => ({
      ...al,
      allergies: al?.allergies,
      id: i,
    }));
    setAllergies(reqList ?? []);
  }, [petData]);

  useEffect(() => {
    const reqList = allergyList
      ?.map((al) => ({ ...al, id: al?.allergiesId }))
      ?.filter((al) =>
        al?.allergies?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
    setFilteredAllergies(reqList);
  }, [searchValue]);

  const handleModClose = () => {
    setAllergyModOpen(false);
    setAllergies([]);
    setSearchValue("");
  };

  const handleSaveAllergies = () => {
    const data = { allergies: allergies?.map((al) => al?.allergies) };
    dispatch(
      updateAllergiesByPetId({
        petId: apntId ? updatedApp?.petId : petId,
        data,
      })
    ).then((res) => {
      if (res?.payload) {
        setSearchValue("");
        setAllergies([]);
        if (updatedApp?.appoimentId) {
          dispatch(getPetsCompliantSummary(updatedApp?.appoimentId ?? apntId));
        } else {
          dispatch(getPetDetailsById(petId));
        }
        dispatch(getClinicAllergies());
        setAllergyModOpen(false);
      }
    });
  };

  const handleAddAllergy = (itm) => {
    if (itm) {
      const isPreAlrgy = allergies?.find((al) => al?.id === itm?.id);
      setSearchValue("");
      if (isPreAlrgy) return;
      setAllergies([...allergies, itm]);
    } else {
      if (searchValue === "") return;
      setAllergies([...allergies, { allergies: searchValue, id: Date.now() }]);
      setSearchValue("");
    }
  };

  const handleRemoveAllergy = (itm) => {
    const reqList = allergies?.filter((alrgy) => alrgy?.id !== itm?.id);
    setAllergies(reqList);
  };

  return (
    <div>
      <CustomModal
        open={allergyModOpen}
        onClose={handleModClose}
        header="Allergies"
        rightModal
        modalWidth={30}
      >
        <div className="ph20">
          {allergies?.length > 0 && (
            <div className="flex-row-wrap1">
              {allergies?.map((alrgy, i) => (
                <div
                  className="flex-row-ali-cen ph10pv5 cursor"
                  onClick={() => handleRemoveAllergy(alrgy)}
                  key={i}
                >
                  <Typography className="font-bold fs10 orange5">
                    {alrgy?.allergies}
                  </Typography>
                  <img
                    alt=""
                    src={require("../../../../assets/images/png/closeBlack.png")}
                    className="img-wh15 ml5"
                  />
                </div>
              ))}
            </div>
          )}

          <div
            className={`flex-row-ali-cen w100Per ${
              allergies?.length > 0 && "mt20"
            }`}
          >
            <div className="w85Per pr10">
              <CustomTextField
                placeholder="Search for allergies"
                fullWidth
                handleChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
            <div className="w15Per">
              <CustomButton
                text="Add"
                smallBtn
                onClick={() => handleAddAllergy(null)}
              />
            </div>
          </div>

          {searchValue?.length > 0
            ? filteredAllergies?.length > 0 && (
                <div className="flex-row-wrap1 mt20">
                  {filteredAllergies?.map((alrgy, i) => (
                    <Typography
                      className="font-bold fs10 allergy-txt cursor"
                      onClick={() => handleAddAllergy(alrgy)}
                      key={i}
                    >
                      {alrgy?.allergies}
                    </Typography>
                  ))}
                </div>
              )
            : null}

          <div className="flex1-end mt50">
            <div className="w15Per">
              <CustomButton
                text="Save"
                smallBtn
                onClick={handleSaveAllergies}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Allergies;
