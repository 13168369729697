import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CallIcon from "@mui/icons-material/Call";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MailIcon from "@mui/icons-material/Mail";
import MedicationIcon from "@mui/icons-material/Medication";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import { Card, CardMedia, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/images/png/edit2.png";
import PlusIcon from "../../../assets/images/png/plus.png";
import Custom3dotString from "../../../components/Custom3dotString";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  checkedCallInApi,
  checkedInApi,
  dischargePatientId,
  updateCompleted,
  updateFinalize,
} from "../../../redux/reducers/clinicSlice";
import {
  addCoOwnerByPetId,
  admitPet,
  getPetDetailsById,
  getPetsCompliantSummary,
} from "../../../redux/reducers/petSlice";
import {
  getClinicVets,
  updateAppointmentStatus,
} from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import Allergies from "../../Hospital/ClinicPetDetails/PatientWorkbook/Allergies";
import InternalTags from "../../Hospital/ClinicPetDetails/PatientWorkbook/InternalTags";
import ClinicPetBookAppointment from "../../Hospital/ClinicPets/ClinicPetBookAppointment";
import "./CommonClinicPetDetails.css";
import InternalNotes from "../../Hospital/ClinicPetDetails/PatientWorkbook/InternalNotes";
import CreateClinicPet from "../../CommonComponents/CreateClinicPet";

const initAdmitValues = { kennelNumber: "", remark: "", vet: null };
const initAdmitErrors = { kennelNumber: false, remark: false, vet: false };
const initCoOwnValues = { name: "", email: "", mobile: "" };
const initCoOwnErrors = { name: false, email: false, mobile: false };

const CommonClinicPetDetails = ({
  children,
  upcomingVisible,
  setVideoCallEnabled,
  appointment,
  scrollable = true,
  setSelectedTab,
  setInpatientId,
  setInpatient,
  inpatient,
  allergyModOpen,
  setAllergyModOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectPet, setSelectPet] = useState();
  const [modalBookVisible, setModalBookVisible] = useState(false);
  const [coOwnerModalVisible, setCoOwnerModalVisible] = useState(false);
  const [intrnalTagModVsble, setIntrnalTagModVsble] = useState(false);
  const [intrnalNoteModVsble, setIntrnalNoteModVsble] = useState(false);
  const [admitModVisible, setAdmitModVisible] = useState(false);
  const [admitValues, setAdmitValues] = useState(initAdmitValues);
  const [admitErrors, setAdmitErrors] = useState(initAdmitErrors);
  const [vets, setVets] = useState([]);
  const [coOwnDetails, setCoOwnDetails] = useState(initCoOwnValues);
  const [coOwnErrors, setCoOwnErrors] = useState(initCoOwnErrors);
  const [disCofrmModVsble, setDisConfrmModVsble] = useState(false);
  const clinicVets = useSelector((state) => state?.vet?.vets);
  const appData = useSelector((state) => state?.pet?.complaintSummary?.data);
  const petDetails = useSelector((state) => state?.pet?.petDetails);
  const apntId = appointment?.appoinment?.appoimentId;
  const petId = appointment?.appoinment?.petId;
  const petData = apntId ? appData?.pet : petDetails;
  const updatedApp = appData?.appointment;
  const apntDet = updatedApp ?? appointment?.appoinment;
  const [createPetModVsble, setCreatePetModVsble] = useState(false);

  useEffect(() => {
    dispatch(getClinicVets());
  }, []);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    setVets(reqVetList);
  }, [clinicVets]);

  const getcompliantSummary = () => {
    dispatch(getPetsCompliantSummary(apntDet?.appoimentId));
  };

  const bookAppointment = () => {
    setSelectPet(petData);
    setModalBookVisible(true);
  };

  const openCoOwnerModal = () => {
    setCoOwnerModalVisible(true);
  };

  const openIntrnalTagModal = () => {
    setIntrnalTagModVsble(true);
  };

  const openIntrnalNotModal = () => {
    setIntrnalNoteModVsble(true);
  };

  const callButtonApi = () => {
    if (apntDet?.appoinmentType === "Virtual") {
      handleJoinVideoCall();
    } else if (apntDet?.appoinmentStatus === "scheduled") {
      const data = {
        appointmentId: apntDet?.appoimentId,
        doctorId: apntDet?.doctorId,
      };

      dispatch(checkedInApi(data)).then((response) => {
        if (response?.payload) {
          getcompliantSummary();
        }
      });
    } else if (apntDet?.appoinmentStatus === "inprogress") {
      const data = {
        appointmentId: apntDet?.appoimentId,
        doctorId: apntDet?.doctorId,
      };
      dispatch(checkedCallInApi(data)).then((response) => {
        if (response?.payload) {
          getcompliantSummary();
        }
      });
    } else if (apntDet?.appoinmentStatus === "consultation") {
      dispatch(updateFinalize(apntDet?.appoimentId)).then((response) => {
        if (response?.payload) {
          getcompliantSummary();
        }
      });
    } else {
      dispatch(updateCompleted(apntDet?.appoimentId)).then((response) => {
        if (response?.payload) {
          getcompliantSummary();
        }
      });
    }
  };

  const handleJoinVideoCall = () => {
    setVideoCallEnabled(true);
    if (navigator.mediaDevices.getUserMedia !== null) {
      var options = {
        video: true,
        audio: true,
      };
      navigator.getUserMedia(options, function (e) {
        console.log("background error : " + e.name);
      });
    }
    dispatch(
      updateAppointmentStatus({
        appId: apntDet?.appoimentId,
        status: "inprogress",
      })
    );
  };

  const onCloseAdmitModal = () => {
    setAdmitModVisible(false);
    setAdmitValues(initAdmitValues);
    setAdmitErrors(initAdmitErrors);
  };

  const handleAdmitChange = (name, value) => {
    setAdmitValues({ ...admitValues, [name]: value });
    setAdmitErrors({
      ...admitErrors,
      [name]: value !== "" && value !== null ? false : true,
    });
  };

  const handleAdmitPatient = () => {
    if (
      admitValues?.kennelNumber === "" ||
      admitValues?.remark === "" ||
      admitValues?.vet === null
    ) {
      const reqObj = { kennelNumber: false, remark: false, vet: false };
      if (admitValues?.kennelNumber === "") reqObj.kennelNumber = true;
      if (admitValues?.remark === "") reqObj.remark = true;
      if (admitValues?.vet === null) reqObj.vet = true;
      return setAdmitErrors(reqObj);
    }
    const data = {
      appointmentId: updatedApp?.appoimentId,
      kennelNumber: admitValues?.kennelNumber,
      remark: admitValues?.remark,
    };

    dispatch(
      admitPet({
        petId: updatedApp?.petId,
        vetId: admitValues?.vet,
        data,
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        onCloseAdmitModal();
        setInpatientId(res?.payload?.data?.patientId);
        setInpatient(true);
        setSelectedTab("admissionDetails");
        dispatch(getPetsCompliantSummary(updatedApp?.appoimentId));
      }
    });
  };

  const handleDischarge = () => {
    setDisConfrmModVsble(true);
  };

  const handleCoOwnModClose = () => {
    setCoOwnDetails(initCoOwnValues);
    setCoOwnErrors(initCoOwnErrors);
    setCoOwnerModalVisible(false);
  };

  const handleCoOwnChange = (name, value) => {
    setCoOwnDetails({ ...coOwnDetails, [name]: value });
    setCoOwnErrors({ ...coOwnErrors, [name]: value !== "" ? false : true });
  };

  const handleValidation = () => {
    let errs = {};
    Object.keys(coOwnDetails).forEach((det) => {
      if (coOwnDetails?.[det] === "") {
        errs = { ...errs, [det]: true };
      }
    });
    setCoOwnErrors({ ...coOwnErrors, ...errs });
    return Object.keys(errs).length > 0 ? false : true;
  };

  const handleSaveCoOwner = async () => {
    const validated = handleValidation();
    if (!validated) return;
    const data = {
      name: coOwnDetails?.name,
      email: coOwnDetails?.email,
      mobile: coOwnDetails?.mobile,
    };
    dispatch(addCoOwnerByPetId({ petId: petData?.petId, data })).then((res) => {
      if (res?.payload) {
        handleCoOwnModClose();
        if (apntId) {
          dispatch(getPetsCompliantSummary(updatedApp?.appoimentId));
        } else {
          dispatch(getPetDetailsById(petId));
        }
      }
    });
  };

  const handleCloseDisCnfrm = () => {
    setDisConfrmModVsble(false);
  };

  const handleConfirmDischarge = () => {
    dispatch(dischargePatientId(appData?.patientId)).then((res) => {
      if (res?.payload) {
        // dispatch(getPetsCompliantSummary(updatedApp?.appoimentId));
        navigate("/dashboard");
      }
    });
  };

  const onCloseCreateMod = (e) => {
    if (e === "petCreated") {
      if (apntId) {
        dispatch(getPetsCompliantSummary(updatedApp?.appoimentId));
      } else {
        dispatch(getPetDetailsById(petId));
      }
    }
    setCreatePetModVsble(false);
  };

  return (
    <>
      <Grid container className="back-white ph20">
        <Grid
          item
          xs={12}
          sm={12}
          md={2.5}
          lg={2.5}
          xl={2.5}
          className="back-white border-rt"
        >
          <div className="scroll-90vh">
            <Container maxWidth="xl">
              <Typography className="card-head-darkblue-color card-head-border-bottom-blue font-bold fs14 capitalize mv5 blue-color mt20">
                Pet Type : {petData?.petType}
              </Typography>
              <Grid container className="mv20">
                <div className="flex-row-ali-cen">
                  {petData?.petImage ? (
                    <img src={petData?.petImage} alt="" className="img3" />
                  ) : (
                    <div className="flex-center h50img">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {petData?.petName?.[0]}
                      </Typography>
                    </div>
                  )}

                  <div className="flex-column ml10">
                    <div className="flex-row">
                      <div className="flex-center">
                        <Custom3dotString
                          str={petData?.petName}
                          className="font-bold fs14 mv5"
                        />

                        <Typography
                          className={`ml5 font-medium fs14 capitalize card-rose-color ${
                            petData?.gender === "male"
                              ? "card-blue-color"
                              : "card-rose-color"
                          }`}
                        >
                          {`(${petData?.gender})`}
                        </Typography>
                      </div>
                    </div>
                    <Typography className="mb10 txt-regular table-gray fs12">
                      {petData?.breed}
                    </Typography>
                  </div>
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="flex-row">
                    <div className="flex-start">
                      <Typography className="txt-regular gray-color fs12">
                        {moment(
                          petData?.dob ?? location?.state?.pet?.dob
                        ).fromNow()}{" "}
                        ({petData?.dob ?? location?.state?.pet?.dob})
                      </Typography>
                    </div>

                    <div className="flex1-end">
                      <img
                        src={require("../../../assets/images/png/weight.png")}
                        alt=""
                        className="img4"
                      />
                      {/* <ScaleOutlinedIcon
                      sx={{ width: 15, height: 15, color: AppColors.black }}
                    /> */}
                      <Typography className="font-bold fs12 ml5">
                        {petData?.weight} KG
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mt10"
                >
                  <Typography className="txt-regular fs12">
                    Neutered/Spayed :{" "}
                    {petData?.isSpayed === true ? "Yes" : "No"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mt10"
                >
                  <Typography className="txt-regular capitalize fs12">
                    Colour : {petData?.color}
                  </Typography>
                </Grid>
                <div className="dashed-card mv20 p20">
                  <div className="flex-row">
                    <AccountCircleOutlinedIcon sx={{ width: 25, height: 25 }} />

                    <Custom3dotString
                      str={petData?.userName}
                      className="font-bold black fs12 ml10 flex-center"
                      length={15}
                    />
                  </div>
                  <div className="flex-row mt10">
                    <MailIcon
                      sx={{
                        color: AppColors.appPrimary,
                        width: 20,
                        height: 20,
                      }}
                    />
                    <div className="flex-center">
                      <Typography className="txt-regular card-gray-color fs12 ml10 ovrflw-wrp-anywre">
                        {petData?.userEmail}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex-row mt10">
                    <CallIcon
                      sx={{
                        color: AppColors.appPrimary,
                        width: 20,
                        height: 20,
                      }}
                    />
                    <div className="flex-center">
                      <Typography className="txt-regular card-gray-color fs12 ml10">
                        {petData?.userMobile}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* <div className="w30Per">
                <CustomButton
                  text="Chat"
                  leftIcon
                  startIcon={<ChatOutlinedIcon />}
                />
              </div> */}
                {!inpatient && apntId && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="mv5 flex-start">
                      {apntDet?.appoinmentStatus === "scheduled" ||
                      apntDet?.appoinmentStatus === "inprogress" ||
                      apntDet?.appoinmentStatus === "consultation" ||
                      apntDet?.appoinmentStatus === "finalize" ||
                      apntDet?.appoinmentType === "Virtual" ? (
                        <div className="">
                          <CustomButton
                            text={` ${
                              apntDet?.appoinmentType === "Virtual" &&
                              apntDet?.appoinmentStatus !== "completed"
                                ? "Join Call"
                                : apntDet?.appoinmentType === "Physical"
                                ? apntDet?.appoinmentStatus === "scheduled"
                                  ? "Check-in"
                                  : apntDet?.appoinmentStatus === "inprogress"
                                  ? "Call-in"
                                  : apntDet?.appoinmentStatus === "consultation"
                                  ? "Finalize"
                                  : "Checkout"
                                : ""
                            } `}
                            onClick={() => {
                              apntDet?.appoinmentType === "Virtual"
                                ? handleJoinVideoCall()
                                : callButtonApi();
                            }}
                            smallBtn
                          />
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mt10"
                >
                  <Typography className="text-bold mb10 fs14">
                    Shortcuts
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="flex-row mv5 cursor">
                    <CalendarMonthOutlinedIcon
                      sx={{ width: 30, height: 30, color: AppColors.gray2 }}
                    />
                    <div className="ml10 flex-center" onClick={bookAppointment}>
                      <Typography className="txt-regular fs14">
                        Book an appointment
                      </Typography>
                    </div>
                  </div>
                </Grid>
                {!inpatient && updatedApp?.appoinmentType === "Virtual" && (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="flex-row mv5 cursor">
                        <DescriptionOutlinedIcon
                          sx={{
                            width: 30,
                            height: 30,
                            color: AppColors.gray2,
                          }}
                        />
                        <div className="ml10 flex-center">
                          <Typography className="txt-regular fs14">
                            Patient Files
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="flex-row mv5 cursor">
                        <OfflineBoltOutlinedIcon
                          sx={{
                            width: 30,
                            height: 30,
                            color: AppColors.gray2,
                          }}
                        />
                        <div className="ml10 flex-center">
                          <Typography className="txt-regular fs14">
                            Send for senior consult
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </>
                )}
                {updatedApp?.inpatient ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      className="flex-row mv5 cursor"
                      onClick={handleDischarge}
                    >
                      <BookmarkRemoveIcon
                        sx={{ width: 30, height: 30, color: AppColors.gray2 }}
                      />
                      <div className="ml10 flex-center">
                        <Typography className="txt-regular fs14">
                          Discharge Patient
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                ) : updatedApp?.appoinmentStatus === "finalize" ||
                  updatedApp?.appoinmentStatus === "completed" ||
                  !apntId ? null : (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      className="flex-row mv5 cursor"
                      onClick={() => setAdmitModVisible(true)}
                    >
                      <MedicationIcon
                        sx={{ width: 30, height: 30, color: AppColors.gray2 }}
                      />
                      <div className="ml10 flex-center">
                        <Typography className="txt-regular fs14">
                          Admit Patient
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Container>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={upcomingVisible ? 7 : 9.5}
          lg={upcomingVisible ? 7 : 9.5}
          xl={upcomingVisible ? 7 : 9.5}
          className={`${upcomingVisible ? "border-rt" : ""} ${
            scrollable ? "scrollable-content" : ""
          }`}
        >
          <Container maxWidth="xl">{children}</Container>
        </Grid>
        {upcomingVisible ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={2.5}
            lg={2.5}
            xl={2.5}
            className="back-white"
          >
            <div className="scroll-90vh">
              <Grid container className="mv20 ph20">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="flex-row-between">
                    <Typography className="text-bold mb10 fs14 blue-color">
                      Internal Tags
                    </Typography>
                    <img
                      src={
                        petData?.petInternalTags?.length > 0
                          ? EditIcon
                          : PlusIcon
                      }
                      onClick={openIntrnalTagModal}
                      className="img-wh15 cursor"
                      alt=""
                    />
                  </div>
                  {petData?.petInternalTags?.length > 0 && (
                    <div className="flex-row-wrap1 mt10">
                      {petData?.petInternalTags?.map((tg) => (
                        <div
                          className="flex-row-ali-cen pv5 ph10 cursor br5 mh5 mv5"
                          style={{ background: tg?.color }}
                          key={tg?.internalId}
                        >
                          <Typography className="font-bold fs10 white-color">
                            {tg?.tags}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="flex-row-between mt10">
                    <Typography className="text-bold mb10 fs14 blue-color">
                      Internal Notes
                    </Typography>
                    <img
                      src={
                        petData?.internalNotes?.[0]?.notes &&
                        petData?.internalNotes?.[0]?.notes?.length !== 0
                          ? EditIcon
                          : PlusIcon
                      }
                      onClick={openIntrnalNotModal}
                      className="img-wh15 cursor"
                      alt=""
                    />
                  </div>

                  {petData?.internalNotes?.[0] && (
                    <div className="flex-row-wrap1">
                      <Typography className="ph10 text">
                        {petData?.internalNotes?.[0]?.notes}
                      </Typography>
                    </div>
                  )}

                  <Typography className="text-medium mb10 fs14 red-color mt10">
                    Outstanding Balance : 45.00
                  </Typography>
                  <Typography className="text-medium mb10 fs14 green-color">
                    Client Credit : 22.00
                  </Typography>

                  <div className="flex-row-ali-cen">
                    <Typography className="txt-semi-bold fs14">
                      App Status:
                    </Typography>

                    <div className="ml20">
                      <CustomButton text="Installed" smallBtn />
                    </div>
                  </div>

                  <div className="flex-row-between mt20">
                    <Typography className="text-bold mb10 fs14 blue-color">
                      Other Pets
                    </Typography>
                    <img
                      src={PlusIcon}
                      alt=""
                      className="img-wh15 cursor"
                      onClick={() => setCreatePetModVsble(true)}
                    />
                  </div>

                  <div className="flex-row scrollx">
                    {(apntId ? appData : petData)?.otherPets
                      ?.filter((pet) => pet?.petId !== petData?.petId)
                      ?.map((pet, i) => (
                        <div key={i + pet?.petId} className="mh10">
                          {pet?.petImage ? (
                            <img src={pet?.petImage} alt="" className="img3" />
                          ) : (
                            <div className="flex-center h50img">
                              <Typography className="font-bold fs30 white-color capitalize">
                                {pet?.petName?.[0]}
                              </Typography>
                            </div>
                          )}
                          <Custom3dotString
                            str={pet?.petName}
                            className="fs12 mt5 txt-semi-bold flex-center gray4"
                            length={6}
                          />
                        </div>
                      ))}
                  </div>

                  <div className="flex-row-between mt20">
                    <Typography className="text-bold mb10 fs14 blue-color">
                      Co-Owner
                    </Typography>
                    {petData?.coOwners?.length > 0 ? null : (
                      <img
                        src={PlusIcon}
                        alt=""
                        className="img-wh15 cursor"
                        onClick={openCoOwnerModal}
                      />
                    )}
                  </div>

                  <div className="flex-row scrollx">
                    {petData?.coOwners?.slice(0, 1)?.map((own, i) => (
                      <div className="dashed-card p20">
                        <div className="flex-row">
                          <AccountCircleOutlinedIcon
                            sx={{ width: 25, height: 25 }}
                          />

                          <Custom3dotString
                            str={own?.name}
                            className="font-bold black fs12 ml10 flex-center"
                            length={15}
                          />
                        </div>
                        <div className="flex-row mt10">
                          <MailIcon
                            sx={{
                              color: AppColors.appPrimary,
                              width: 20,
                              height: 20,
                            }}
                          />
                          <div className="flex-center">
                            <Typography className="txt-regular card-gray-color fs12 ml10 ovrflw-wrp-anywre">
                              {own?.email}
                            </Typography>
                          </div>
                        </div>
                        <div className="flex-row mt10">
                          <CallIcon
                            sx={{
                              color: AppColors.appPrimary,
                              width: 20,
                              height: 20,
                            }}
                          />
                          <div className="flex-center">
                            <Typography className="txt-regular card-gray-color fs12 ml10">
                              {own?.mobile}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      // <div key={i + own?.ownerId} className="mh10">
                      //   <div className="flex-center h50img">
                      //     <Typography className="font-bold fs30 white-color capitalize">
                      //       {own?.name?.[0]}
                      //     </Typography>
                      //   </div>

                      //   <Custom3dotString
                      //     str={own?.name}
                      //     className="fs12 mt5 txt-semi-bold flex-center gray4"
                      //     length={6}
                      //   />
                      // </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : null}
        <ClinicPetBookAppointment
          modalVisible={modalBookVisible}
          setModalBookVisible={setModalBookVisible}
          selectPet={selectPet}
        />
        <InternalTags
          intrnalTagModVsble={intrnalTagModVsble}
          setIntrnalTagModVsble={setIntrnalTagModVsble}
          updatedApp={updatedApp}
          appoinment={appointment}
        />
        <InternalNotes
          intrnalNoteModVsble={intrnalNoteModVsble}
          setIntrnalNoteModVsble={setIntrnalNoteModVsble}
          updatedApp={updatedApp}
          appoinment={appointment}
        />

        <Allergies
          allergyModOpen={allergyModOpen}
          setAllergyModOpen={setAllergyModOpen}
          updatedApp={updatedApp}
          appointment={appointment}
        />
      </Grid>

      <CreateClinicPet
        createModVisible={createPetModVsble}
        onCloseCreateMod={onCloseCreateMod}
        userDetails={{
          id: apntId ? appData?.pet?.userId : petDetails?.userId,
          email: apntId ? appData?.pet?.userEmail : petDetails?.userEmail,
          name: apntId ? appData?.pet?.userName : petDetails?.userName,
          mobile: apntId ? appData?.pet?.userMobile : petDetails?.userMobile,
        }}
      />

      <CustomModal
        open={admitModVisible}
        onClose={onCloseAdmitModal}
        header="Admit Patient"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20">
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: 1,
                padding: 2,
                borderTopColor: AppColors.appPrimary,
              }}
              className="CustomCard-back-appointment"
            >
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {petData?.petImage ? (
                        <CardMedia
                          image={petData?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {petData?.petName?.[0]}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div
                            variant="body1"
                            className="mb10 txt-semi-bold black fs14 capitalize"
                          >
                            {petData?.petName}
                          </div>
                          <Typography
                            variant="body1"
                            className={`ml5 text capitalize ${
                              petData?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${petData?.gender})`}
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className="mb10 txt-regular card-gray-color fs12"
                        >
                          {petData?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {petData?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(petData?.dob).fromNow()} ({petData?.dob})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">Kennel Number</div>
            <CustomTextField
              name="kennelNumber"
              value={admitValues?.kennelNumber}
              fullWidth
              handleChange={(e) =>
                handleAdmitChange("kennelNumber", e?.target?.value)
              }
              error={admitErrors?.kennelNumber}
              helperText={
                admitErrors?.kennelNumber
                  ? "Kennel Number is required field"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">Reason</div>
            <CustomTextField
              fullWidth
              multiline
              rows={3}
              handleChange={(e) =>
                handleAdmitChange("remark", e?.target?.value)
              }
              value={admitValues?.remark ?? ""}
              error={admitErrors?.remark}
              helperText={admitErrors?.remark ? "Reason is required field" : ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">Vet Name</div>
            <Select
              list={vets}
              handleChange={(e) => handleAdmitChange("vet", e?.target?.value)}
              name="vet"
              select
              value={admitValues?.vet}
              error={admitErrors?.vet}
              helperText={admitErrors?.vet ? "Vet Name is required field" : ""}
            />
          </Grid>
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleAdmitPatient} />
          </Grid>
        </div>
      </CustomModal>

      <CustomModal
        open={coOwnerModalVisible}
        onClose={handleCoOwnModClose}
        header="Co-Owner Details"
        rightModal
        modalWidth={30}
      >
        <Grid container spacing={2} className="ph20">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="text-bold fs14 mb5 ">Name</div>
            <CustomTextField
              name="name"
              fullWidth
              value={coOwnDetails?.name}
              handleChange={(e) => handleCoOwnChange("name", e?.target?.value)}
              error={coOwnErrors?.name}
              helperText={coOwnErrors?.name ? "Name is required field" : ""}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="mb20">
            <div className="text-bold fs14 mb5 ">Email</div>
            <CustomTextField
              name="email"
              fullWidth
              value={coOwnDetails?.email}
              handleChange={(e) => handleCoOwnChange("email", e?.target?.value)}
              error={coOwnErrors?.email}
              helperText={coOwnErrors?.email ? "Email is required field" : ""}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className="mb20">
            <div className="text-bold fs14 mb5 ">Contact Number</div>
            <CustomTextField
              name="mobile"
              fullWidth
              value={coOwnDetails?.mobile}
              handleChange={(e) =>
                handleCoOwnChange("mobile", e?.target?.value)
              }
              error={coOwnErrors?.mobile}
              helperText={coOwnErrors?.mobile ? "Mobile is required field" : ""}
            />
          </Grid>

          <div className="flex1-end">
            <div>
              <CustomButton text="Save" smallBtn onClick={handleSaveCoOwner} />
            </div>
          </div>
        </Grid>
      </CustomModal>

      <CustomModal
        open={disCofrmModVsble}
        onClose={handleCloseDisCnfrm}
        header="Discharge Pet"
        modal
        modalWidth={40}
      >
        <Typography className="txt-regular fs16 ml10">
          Are you sure you want to Discharge the patient?
        </Typography>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="clinic-mod-btn-pos mb10 mt15">
            <div className="mr10">
              <CustomButton
                text="Yes"
                redBtn
                onClick={handleConfirmDischarge}
              />
            </div>
            <div className="ml10">
              <CustomButton text="No" onClick={handleCloseDisCnfrm} />
            </div>
          </div>
        </Grid>
      </CustomModal>
    </>
  );
};

export default CommonClinicPetDetails;
