import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Card, CardMedia, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../../components/CustomCard/CustomCard";
import Select from "../../../components/Select/Select";
import TopBar from "../../../components/TopBar/TopBar";
import api from "../../../redux/actions/api";
import "./Home.css";

import { merge, uniq } from "lodash";
import moment from "moment";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import {
  checkedCallInApi,
  checkedInApi,
  clinicRescheduleAppointment,
  getClinicDashboardData,
  getClinicHolidays,
  updateCancel,
  updateCompleted,
  updateFinalize,
} from "../../../redux/reducers/clinicSlice";
import { getVetHolidaysById } from "../../../redux/reducers/doctorSlice";
import { admitPet, getSlotTime } from "../../../redux/reducers/petSlice";
import { getClinicVets } from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import { conditionList } from "../../../util/arrayList";
import {
  serviceType,
  typeAppointemnt,
  typeList,
  typeListNew,
} from "../../../util/dropList";
import { getAllDatesFromArray } from "../../../util/function";
import Inpatient from "./Inpatient";

const initialValues = {
  typeOfAppointment: "Phone",
  serviceType: "Consultation",
  consultationType: "Virtual",
  reason: "",
  vetName: "",
  dob: "",
};

const initAdmitValues = { kennelNumber: "", remark: "", vet: null };
const initAdmitErrors = { kennelNumber: false, remark: false, vet: false };

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slotList = useSelector((state) => state.pet.slotList);
  const activeSlotList = slotList?.filter((status) => status.status === "N");
  const [selectedTab, setSelectedTab] = useState("Outpatient");
  const dashboard = useSelector((state) => state.clinic.dashboard);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [petValues, setPetValues] = useState(initialValues);
  const [modalData, setModalData] = useState(null);
  const clinicVets = useSelector((state) => state.vet.vets);
  const [vets, setVets] = useState([]);
  const [vetListWithAll, setVetListWithAll] = useState([]);
  const [selectedVet, setSelectedVet] = useState("All");
  const [selectTime, setSelectTime] = useState();
  const [selectTimeId, setSelectTimeId] = useState("");
  const [delModVisible, setDelModVisible] = useState(false);
  const [rescheduleModVsble, setRescheduleModVsble] = useState(false);
  const [followUpModVsble, setFollowUpModVsble] = useState(false);
  const [selectTimeIdError, setSelectTimeIdError] = useState(false);
  const [conMode, setConMode] = useState(null);
  const [serType, setSerType] = useState(null);
  const [reason, setReason] = useState(null);
  const [bookingType, setBookingType] = useState("Walk-in");
  const [doctor, setDoctor] = useState(modalData?.appoinment?.doctorName);
  const [selectedVetId, setSelectedVetId] = useState(null);
  const [appointmentError, setAppointmentError] = useState(false);
  const [appointmentOption, setAppointmentOption] = useState("Immediate");
  const [admitModVisible, setAdmitModVisible] = useState(false);
  const [admitValues, setAdmitValues] = useState(initAdmitValues);
  const [admitErrors, setAdmitErrors] = useState(initAdmitErrors);
  const allObj = { name: "All", value: "All", id: 1 };
  const holidays = useSelector((state) => state?.doctor?.holidays);
  const [holidayDates, setHolidayDates] = useState([]);
  const clinicHolidays = useSelector((state) => state?.clinic?.holidays);

  const [errors, setErrors] = useState({
    appointmentDate: "",
    appointmentTime: "",
    doctor: "",
  });

  useEffect(() => {
    checkLocalStorageforReload();
    getProfile(); // get and store user details
    dispatch(getClinicVets());
    dispatch(getClinicHolidays());
  }, []);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    setVets(reqVetList);
    setVetListWithAll([allObj].concat(reqVetList));
  }, [clinicVets]);

  useEffect(() => {
    // Refetch the dashboard data whenever the selected veterinarian changes
    if (selectedTab === "Outpatient" || selectedTab === "Virtual") {
      const reqObj = { type: selectedTab };
      if (selectedVet !== "All") reqObj.url = `&doctorId=${selectedVet}`;
      dispatch(getClinicDashboardData(reqObj));
    }
  }, [selectedVet]);

  useEffect(() => {
    getHolidayDateList();
  }, [holidays, clinicHolidays]);

  const getHolidayDateList = async () => {
    if (
      (holidays?.length === 0 || !holidays) &&
      (clinicHolidays?.length === 0 || !clinicHolidays)
    ) {
      return setHolidayDates([]);
    }
    const vetDates = (await getAllDatesFromArray(holidays)) ?? [];

    const cliHolidays =
      clinicHolidays?.map((hl) => {
        return hl?.date;
      }) ?? [];
    const mergedArray = vetDates?.concat(cliHolidays);
    setHolidayDates(uniq(mergedArray));
  };

  const createModalOpen = (li) => {
    setRescheduleModVsble(!rescheduleModVsble);
    setRescheduleData(li);
    setModalData(li);
  };

  const rescheduleModClose = () => {
    setRescheduleModVsble(false);
    setDoctor(null);
    setSelectedVetId(null);
    setModalData(null);
    setAppointmentOption(null);
  };

  const createModalOpenFollowUp = (li) => {
    setFollowUpModVsble(!followUpModVsble);
    setRescheduleData(li);
    setModalData(li);
    setConMode(li?.appoinment?.appoinmentType);
    setReason(li?.appoinment?.reason);
    setSerType(li?.appoinment?.appoinmentStatus);
  };

  const onCloseFollowUpMod = () => {
    setFollowUpModVsble(false);
    setDoctor(null);
    setSelectedVetId(null);
    setModalData(null);
    setAppointmentOption(null);
  };

  const handleAppointmentOptionChange = (event) => {
    setAppointmentOption(event.target.value);
    setAppointmentError(false);
  };

  const handleDelModClose = (li) => {
    setDelModVisible(!delModVisible);
    setModalData(li);
  };

  const handleSelectTime = (time) => {
    setSelectTime(time?.time);
    setSelectTimeId(time?.slotTimeId);
    setSelectTimeIdError(false);
  };

  const handleDateClick = (day, vetId = null, appType = null) => {
    const date = moment(new Date(day)).format("YYYY-MM-DD");
    const doctorId = selectedVetId ?? vetId;

    const contType = appType ?? petValues?.consultationType;

    const metaData = { doctorId, contType, date };
    dispatch(getSlotTime(metaData));
  };

  const validateFields = () => {
    const newErrors = {};
    // if (!appointmentDate) newErrors.appointmentDate = "This field is required";
    // if (!appointmentTime) newErrors.appointmentTime = "This field is required";
    if (!doctor) newErrors.doctor = "This field is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const checkLocalStorageforReload = async () => {
    const videoCallPageReloaded = await JSON.parse(
      localStorage.getItem("videoCallPageReloaded")
    );
    if (videoCallPageReloaded) {
      navigate(`/clinic-pet-details`, {
        state: { appointment: { appoinment: videoCallPageReloaded?.state } },
      });
      return await localStorage.removeItem("videoCallPageReloaded");
    }
  };

  const getProfile = async () => {
    const res = await api({ contentType: true, auth: true }).get(
      "/user/profile"
    );
    localStorage.setItem("user", JSON.stringify(res.data.data));
  };

  const navigatePetDetailsScreen = (appointment) => {
    navigate(`/clinic-pet-details`, { state: { appointment } });
  };

  const filterAppointmentsByVet = (appointments) => {
    return appointments?.filter((itm) => !itm?.appoinment?.inpatient);
  };

  const filterAppointments = (data, type) =>
    data.filter((item) => item.appoinment.appoinmentType === type);

  const handleAdmit = (item) => {
    setModalData(item);
    setAdmitModVisible(true);
  };

  const renderSection = (
    title,
    data,
    colorClass,
    sectionType,
    checkIn,
    threedots
  ) => (
    <div className="w20Per borderRight">
      <div className="flex-row">
        <div className="p10MinWid99Per ">
          <Typography
            variant="h6"
            className={`card-head-${colorClass}-color card-head-border-bottom-${colorClass} font-bold fs20`}
          >
            {title} ({data?.length ?? 0})
          </Typography>
          <CustomCard
            list={data ?? []}
            dashboard
            // topBarClassName={`card-top-${colorClass}-color`}
            sectionType={sectionType}
            onClickResch={(li) => createModalOpen(li)}
            onReassign={(li) => createModalOpen(li)}
            handleAction={(li) => handleAction(li)}
            handleCancel={(li) => handleDelModClose(li)}
            handleCompleted={(li) => handleCompleted(li)}
            onFollowUp={(li) => {
              createModalOpenFollowUp(li);
            }}
            onClick={(li) => navigatePetDetailsScreen(li)}
            checkIn={checkIn}
            threedots={threedots}
            handleConsult={(li) => handleConsult(li)}
            handleCheckout={(li) => handleCheckout(li)}
            handleAdmit={(itm) => handleAdmit(itm)}
          />
        </div>
      </div>
    </div>
  );

  const handleAction = (li, action) => {
    const data = {
      appointmentId: li?.appoinment?.appoimentId,
      doctorId: li?.appoinment?.doctorId,
    };

    dispatch(checkedInApi(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        checkLocalStorageforReload();
        getProfile(); // get and store user details
        dispatch(getClinicDashboardData({ type: selectedTab }));
        dispatch(getClinicVets());
      }
    });
  };
  const handleCancel = () => {
    dispatch(updateCancel(modalData?.appoinment?.appoimentId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setDelModVisible(false);
        setRescheduleModVsble(false);
        checkLocalStorageforReload();
        getProfile(); // get and store user details
        dispatch(getClinicDashboardData({ type: selectedTab }));
        dispatch(getClinicVets());
      }
    });
  };

  const handleConsult = (li, action) => {
    const data = {
      appointmentId: li?.appoinment?.appoimentId,
      doctorId: li?.appoinment?.doctorId,
    };
    dispatch(checkedCallInApi(data)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        checkLocalStorageforReload();
        getProfile(); // get and store user details
        dispatch(getClinicDashboardData({ type: selectedTab }));
        dispatch(getClinicVets());
      }
    });
  };

  const handleCompleted = (li, action) => {
    dispatch(updateFinalize(li?.appoinment?.appoimentId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        checkLocalStorageforReload();
        getProfile(); // get and store user details
        dispatch(getClinicDashboardData({ type: selectedTab }));
        dispatch(getClinicVets());
      }
    });
  };

  const handleCheckout = (li, action) => {
    dispatch(updateCompleted(li?.appoinment?.appoimentId)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        checkLocalStorageforReload();
        getProfile(); // get and store user details
        dispatch(getClinicDashboardData({ type: selectedTab }));
        dispatch(getClinicVets());
      }
    });
  };

  const handleReschedule = () => {
    if (!validateFields()) return;
    const data = {
      // appointmentDate: moment(selectedDate).format("YYYY-MM-DD"),
      appointmentDate: moment(new Date(petValues?.dob)).format("YYYY-MM-DD"),
      appoimentTime: selectTime,
      doctorId: selectedVetId,
    };
    const metaData = {
      appointmentId: modalData?.appoinment?.appoimentId,
      data,
    };
    dispatch(clinicRescheduleAppointment(metaData)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        onCloseFollowUpMod();
        setModalData(null);
      }
    });
  };

  const handleChange = (e) => {
    setPetValues({ ...petValues, [e.target.name]: e.target.value });
  };

  const onCloseAdmitModal = () => {
    setAdmitModVisible(false);
    setAdmitValues(initAdmitValues);
    setAdmitErrors(initAdmitErrors);
    setModalData(null);
  };

  const handleAdmitChange = (name, value) => {
    setAdmitValues({ ...admitValues, [name]: value });
    setAdmitErrors({
      ...admitErrors,
      [name]: value !== "" && value !== null ? false : true,
    });
  };

  const handleAdmitPatient = () => {
    if (
      admitValues?.kennelNumber === "" ||
      admitValues?.remark === "" ||
      admitValues?.vet === null
    ) {
      const reqObj = { kennelNumber: false, remark: false, vet: false };
      if (admitValues?.kennelNumber === "") reqObj.kennelNumber = true;
      if (admitValues?.remark === "") reqObj.remark = true;
      if (admitValues?.vet === null) reqObj.vet = true;
      return setAdmitErrors(reqObj);
    }
    const data = {
      appointmentId: modalData?.appoinment?.appoimentId,
      kennelNumber: admitValues?.kennelNumber,
      remark: admitValues?.remark,
    };

    dispatch(
      admitPet({
        petId: modalData?.appoinment?.petId,
        vetId: admitValues?.vet,
        data,
      })
    ).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        dispatch(getClinicDashboardData({ type: selectedTab }));
        onCloseAdmitModal();
      }
    });
  };

  return (
    <>
      <TopBar>
        <Container maxWidth="xl">
          <Grid container spacing={0} className="flex-center">
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Grid container spacing={0}>
                <div className="flex-row">
                  <div
                    className="ph10pv3"
                    onClick={() => {
                      setSelectedTab("Outpatient");
                      dispatch(getClinicDashboardData({ type: "Outpatient" }));
                    }}
                  >
                    <div
                      className={`flex-center cursor ${
                        selectedTab === "Outpatient"
                          ? "pet-det-sel-tab"
                          : "pet-det-un-sel-tab"
                      }`}
                    >
                      Outpatient
                    </div>
                  </div>
                  <div
                    className="ph10pv3"
                    onClick={() => {
                      setSelectedTab("Virtual");
                      dispatch(getClinicDashboardData({ type: "Virtual" }));
                    }}
                  >
                    <div
                      className={`flex-center cursor ${
                        selectedTab === "Virtual"
                          ? "pet-det-sel-tab"
                          : "pet-det-un-sel-tab"
                      }`}
                    >
                      Virtual
                    </div>
                  </div>
                  <div
                    className="ph10pv3"
                    onClick={() => setSelectedTab("Inpatient")}
                  >
                    <div
                      className={`flex-center cursor ${
                        selectedTab === "Inpatient"
                          ? "pet-det-sel-tab"
                          : "pet-det-un-sel-tab"
                      }`}
                    >
                      Inpatient
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <div className="flex1-end">
                <Grid item xs={8} sm={8} md={6} lg={4} xl={4}>
                  <Select
                    list={vetListWithAll}
                    name="doctorList"
                    value={selectedVet}
                    handleChange={(e) => {
                      const selectedVet = vets?.find(
                        (vet) => vet.value === e.target.value
                      );
                      setSelectedVet(e?.target?.value);
                      setSelectedVetId(
                        selectedVet && selectedVet.value !== "All"
                          ? selectedVet.id
                          : null
                      );
                    }}
                    select
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      </TopBar>
      <div className="mv20">
        <Container maxWidth="xl">
          {/* <div className="flex-center"> */}
          {selectedTab === "Inpatient" && (
            <Inpatient
              setSelectedTab={setSelectedTab}
              selectedVet={selectedVet}
            />
          )}
          {selectedTab === "Outpatient" && (
            <div className="flex-center">
              {renderSection(
                "Upcoming",
                filterAppointmentsByVet(dashboard?.upcomming || []),
                "orange",
                "Upcoming",
                true,
                true
              )}
              {renderSection(
                "Checked-in",
                filterAppointmentsByVet(dashboard?.checkedin || []),
                "rose",
                "Checked-in",
                true,
                true
              )}
              {renderSection(
                "Consultation",
                filterAppointmentsByVet(dashboard?.consultation || []),
                "green",
                "Consultation",
                true,
                true
              )}
              {renderSection(
                "Billing",
                filterAppointmentsByVet(dashboard?.billings || []),
                "yellow",
                "Billing",
                true,
                true
              )}
              {renderSection(
                "Completed",
                filterAppointmentsByVet(dashboard?.completed || []),
                "paleGreen",
                "Completed",
                true,
                true
              )}
            </div>
          )}
          {selectedTab === "Virtual" && (
            <div className="flex-center">
              {renderSection(
                "Upcoming",
                filterAppointments(
                  filterAppointmentsByVet(dashboard?.upcomming || []),
                  "Virtual"
                ),
                "orange",
                "Upcoming",
                false,
                false
              )}
              {renderSection(
                "Consultation",
                filterAppointments(
                  filterAppointmentsByVet(dashboard?.consultation || []),
                  "Virtual"
                ),
                "green",
                "Consultation",
                false,
                false
              )}
              {renderSection(
                "Completed",
                filterAppointments(
                  filterAppointmentsByVet(dashboard?.completed || []),
                  "Virtual"
                ),
                "paleGreen",
                "Completed",
                false,
                false
              )}
            </div>
          )}
          {/* </div> */}
        </Container>
      </div>
      <CustomModal
        open={rescheduleModVsble}
        onClose={rescheduleModClose}
        header="Reschedule / Reassign"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20 scroll-80vh">
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: 1,
                padding: 2,
                borderTopColor: AppColors.appPrimary,
              }}
              className="CustomCard-back-appointment"
            >
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {modalData?.appoinment?.petImage ? (
                        <CardMedia
                          image={modalData?.appoinment?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {modalData?.appoinment?.petName?.[0]}
                          </Typography>
                        </div>
                        // <div className="CustomCard-empty-img" />
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div
                            variant="body1"
                            className="mb10 txt-semi-bold black fs14 capitalize"
                          >
                            {modalData?.appoinment?.petName}
                          </div>
                          <Typography
                            variant="body1"
                            className={`ml5 text capitalize ${
                              modalData?.appoinment?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${modalData?.appoinment?.gender})`}
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className="mb10 txt-regular card-gray-color fs12"
                        >
                          {modalData?.appoinment?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {modalData?.appoinment?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(modalData?.appoinment?.appoinmentDate).fromNow()}{" "}
                      ({modalData?.appoinment?.appoinmentDate})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">Booking Type</div>
            <Select value={"Walk-in"} selectFixed />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Consultation mode
            </div>
            <Select value={"Physical"} selectFixed />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">Service type</div>
            <Select
              // list={serviceType}
              value={"Consultation"}
              // handleChange={(e) => handleSelect(e, "serviceType")}
              selectFixed
              // helperText={petHelpers?.serviceType}
              // error={petErrors?.serviceType}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Reason for visit
            </div>
            <Select
              // list={conditionList}
              value={rescheduleData?.appoinment?.reason}
              // handleChange={(e) => handleSelect(e, "reason")}
              selectFixed
              // multiline

              // helperText={petHelpers?.reason}
              // error={petErrors?.reason}
              // labelTop
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">Vet Name</div>
            <Select
              list={vets}
              handleChange={(e) => {
                const selectedVet = vets?.find(
                  (vet) => vet.value === e.target.value
                );
                dispatch(getVetHolidaysById(selectedVet?.id));
                setSelectedVetId(selectedVet?.id);
                setDoctor(e.target.value);
                handleDateClick(new Date(), selectedVet?.id, "Physical");
              }}
              name="doctorList"
              // label={"Doctor List"}
              select
              value={doctor}
              error={!!errors?.doctor}
              helperText={errors?.doctor}
              labelTop
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mbminus"
          >
            <div className="flex-row">
              <div className="txt-semi-bold fs14 light-grey mt10">
                Appointment :
              </div>
              <div className="ml5">
                <CustomCheckbox
                  radio
                  value={appointmentOption}
                  onChange={handleAppointmentOptionChange}
                  radios={[
                    { label: "Immediate", value: "Immediate" },
                    { label: "Later", value: "Later" },
                  ]}
                />
              </div>
            </div>

            {appointmentError && (
              <Typography color="error" className="ml5 mt5 fs14">
                Appointment is Required
              </Typography>
            )}
          </Grid>

          {appointmentOption === "Later" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 ">Date</div>
              <div className="mb20">
                <CustomTextField
                  name="dob"
                  value={petValues?.dob}
                  labelTop
                  fullWidth
                  handleChange={(e) => {
                    handleChange(e, "dob");
                    handleDateClick(e.target.value, null, "Physical");
                  }}
                  mobileDateWithBatch
                  markDateList={holidayDates ?? []}
                />
              </div>
            </Grid>
          )}

          {appointmentOption === "Later" && petValues?.dob && (
            <>
              {activeSlotList?.map((item, i) => {
                return (
                  <>
                    <div className="flex-row">
                      <div
                        onClick={() => handleSelectTime(item)}
                        className={
                          selectTimeId === item?.slotTimeId
                            ? "bgContainerSelected cursor"
                            : "bgContainerUnselected cursor"
                        }
                      >
                        {item?.time}
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          )}

          {selectTimeIdError && (
            <Typography color="error" className="ml5 mt5 fs14">
              Time is Requried
            </Typography>
          )}
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleReschedule} />
          </Grid>
        </div>
      </CustomModal>
      <CustomModal
        open={followUpModVsble}
        onClose={onCloseFollowUpMod}
        header="Book an appointment"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20 scroll-80vh">
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: 1,
                padding: 2,
                borderTopColor: AppColors.appPrimary,
              }}
              className="CustomCard-back-appointment"
            >
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {modalData?.appoinment?.petImage ? (
                        <CardMedia
                          image={modalData?.appoinment?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {modalData?.appoinment?.petName?.[0]}
                          </Typography>
                        </div>
                        // <div className="CustomCard-empty-img" />
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div
                            variant="body1"
                            className="mb10 txt-semi-bold black fs14 capitalize"
                          >
                            {modalData?.appoinment?.petName}
                          </div>
                          <Typography
                            variant="body1"
                            className={`ml5 text capitalize ${
                              modalData?.appoinment?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${modalData?.appoinment?.gender})`}
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className="mb10 txt-regular card-gray-color fs12"
                        >
                          {modalData?.appoinment?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {modalData?.appoinment?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(modalData?.appoinment?.appoinmentDate).fromNow()}{" "}
                      ({modalData?.appoinment?.appoinmentDate})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">Booking Type</div>
            <Select
              // list={typeAppointemnt}
              list={typeAppointemnt}
              value={bookingType}
              handleChange={(e) => {
                setBookingType(e.target.value);
                handleDateClick(
                  new Date(),
                  null,
                  bookingType === "Walk-in" ? "Physical" : conMode
                );
              }}
              // name="typeOfAppointment"
              select
              labelTop

              // helperText={petHelpers?.typeOfAppointment}
              // error={petErrors?.typeOfAppointment}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="txt-semi-bold fs14 light-grey">
              Consultation mode
            </div>
            <Select
              list={bookingType === "Walk-in" ? typeListNew : typeList}
              // list={typeList}
              // value={petValues.consultationType}
              value={bookingType === "Walk-in" ? "Physical" : conMode}
              // value={conMode}
              handleChange={(e) => {
                setConMode(e.target.value);
                handleDateClick(new Date(), null, e.target.value);
              }}
              // name="consultationType"
              select
              labelTop
              // helperText={petHelpers?.consultationType}
              // error={petErrors?.consultationType}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">Service type</div>
            <Select
              list={serviceType}
              value={serType}
              handleChange={(e) => setSerType(e.target.value)}
              select
              labelTop
              // helperText={petHelpers?.serviceType}
              // error={petErrors?.serviceType}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">
              Reason for visit
            </div>
            <Select
              list={conditionList}
              value={reason}
              handleChange={(e) => setReason(e.target.value)}
              select
              labelTop
              // helperText={petHelpers?.reason}
              // error={petErrors?.reason}
              // labelTop
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">Vet Name</div>
            <Select
              list={vets}
              handleChange={(e) => {
                const selectedVet = vets.find(
                  (vet) => vet.value === e.target.value
                );

                setSelectedVetId(selectedVet?.id);
                setDoctor(e.target.value);
                dispatch(getVetHolidaysById(selectedVet?.id));
                handleDateClick(
                  new Date(),
                  selectedVet?.id,
                  bookingType === "Walk-in" ? "Physical" : conMode
                );
              }}
              name="doctorList"
              // label={"Doctor List"}
              select
              value={doctor}
              error={!!errors?.doctor}
              helperText={errors?.doctor}
              labelTop
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="mbminus"
          >
            <div className="flex-row">
              <div className="txt-semi-bold fs14 light-grey mt10">
                Appointment :
              </div>
              <div className="ml5">
                <CustomCheckbox
                  radio
                  value={appointmentOption}
                  onChange={handleAppointmentOptionChange}
                  radios={[
                    { label: "Immediate", value: "Immediate" },
                    { label: "Later", value: "Later" },
                  ]}
                />
              </div>
            </div>

            {appointmentError && (
              <Typography color="error" className="ml5 mt5 fs14">
                Appointment is Required
              </Typography>
            )}
          </Grid>

          {appointmentOption === "Later" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-mont fs14 ">Date</div>
              <div className="mb20">
                <CustomTextField
                  name="dob"
                  value={petValues?.dob}
                  labelTop
                  fullWidth
                  handleChange={(e) => {
                    handleChange(e, "dob");
                    // handleDateClick(e.target.value);
                    handleDateClick(
                      e.target.value,
                      null,
                      bookingType === "Walk-in" ? "Physical" : conMode
                    );
                  }}
                  mobileDateWithBatch
                  markDateList={holidayDates ?? []}
                />
              </div>
            </Grid>
          )}

          {appointmentOption === "Later" && petValues?.dob && (
            <>
              {activeSlotList?.map((item, i) => {
                return (
                  <>
                    <div className="flex-row">
                      <div
                        onClick={() => handleSelectTime(item)}
                        className={
                          selectTimeId === item?.slotTimeId
                            ? "bgContainerSelected cursor"
                            : "bgContainerUnselected cursor"
                        }
                      >
                        {item?.time}
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          )}

          {selectTimeIdError && (
            <Typography color="error" className="ml5 mt5 fs14">
              Time is Requried
            </Typography>
          )}
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleReschedule} />
          </Grid>
        </div>
      </CustomModal>
      <CustomModal
        open={admitModVisible}
        onClose={onCloseAdmitModal}
        header="Admit Patient"
        rightModal
        modalWidth={30}
        headerBold
      >
        <Grid container spacing={1} className="ph20">
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: 1,
                padding: 2,
                borderTopColor: AppColors.appPrimary,
              }}
              className="CustomCard-back-appointment"
            >
              <div className="maint">
                <div className="flex-row">
                  <Grid item xs={3}>
                    <div className="flex-center">
                      {modalData?.appoinment?.petImage ? (
                        <CardMedia
                          image={modalData?.appoinment?.petImage}
                          className="CustomCard-img3-bill"
                        />
                      ) : (
                        <div className="flex-center h50img">
                          <Typography className="font-bold fs30 white-color capitalize">
                            {modalData?.appoinment?.petName?.[0]}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-column">
                        <div className="flex-row">
                          <div
                            variant="body1"
                            className="mb10 txt-semi-bold black fs14 capitalize"
                          >
                            {modalData?.appoinment?.petName}
                          </div>
                          <Typography
                            variant="body1"
                            className={`ml5 text capitalize ${
                              modalData?.appoinment?.gender === "male"
                                ? "card-blue-color"
                                : "card-rose-color"
                            }`}
                          >
                            {`(${modalData?.appoinment?.gender})`}
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className="mb10 txt-regular card-gray-color fs12"
                        >
                          {modalData?.appoinment?.breed}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div>
                  <div className="flex-row parentcontainer mt10">
                    <div className="flex-row iconcontainer">
                      <AccountCircleOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                      />
                      <div className="txt-semi-bold fs14 capitalize">
                        {modalData?.appoinment?.userName}
                      </div>
                    </div>
                    <div className="meeting-doctor">
                      {moment(modalData?.appoinment?.appoinmentDate).fromNow()}{" "}
                      ({modalData?.appoinment?.appoinmentDate})
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">Kennel Number</div>
            <CustomTextField
              name="kennelNumber"
              value={admitValues?.kennelNumber}
              fullWidth
              handleChange={(e) =>
                handleAdmitChange("kennelNumber", e?.target?.value)
              }
              error={admitErrors?.kennelNumber}
              helperText={
                admitErrors?.kennelNumber
                  ? "Kennel Number is required field"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey">Reason</div>
            <CustomTextField
              fullWidth
              multiline
              rows={3}
              handleChange={(e) =>
                handleAdmitChange("remark", e?.target?.value)
              }
              value={admitValues?.remark ?? ""}
              error={admitErrors?.remark}
              helperText={admitErrors?.remark ? "Reason is required field" : ""}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="txt-semi-bold fs14 light-grey mb10">Vet Name</div>
            <Select
              list={vets}
              handleChange={(e) => handleAdmitChange("vet", e?.target?.value)}
              name="vet"
              select
              value={admitValues?.vet}
              error={admitErrors?.vet}
              helperText={admitErrors?.vet ? "Vet Name is required field" : ""}
            />
          </Grid>
        </Grid>
        <div className="flex1-end mt20">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomButton text="Save" onClick={handleAdmitPatient} />
          </Grid>
        </div>
      </CustomModal>

      <CustomModal
        open={delModVisible}
        onClose={handleDelModClose}
        header="Cancel"
        modal
        modalWidth={40}
      >
        <Typography className="txt-regular fs16 ml10">
          Are you sure you want to cancel the appointment?
        </Typography>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="clinic-mod-btn-pos mb10 mt15">
            <div className="mr10">
              <CustomButton text="Yes" redBtn onClick={handleCancel} />
            </div>
            <div className="ml10">
              <CustomButton
                text={"No"}
                onClick={() => setDelModVisible(false)}
              />
            </div>
          </div>
        </Grid>
      </CustomModal>
    </>
  );
};

export default Home;
