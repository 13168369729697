import { Tooltip, Typography } from "@mui/material";

const Custom3dotString = ({
  str = "",
  length = 8,
  className = "font-bold fs14",
}) => {
  const reqStr =
    str?.length > 0 ? str?.charAt(0).toUpperCase() + str?.slice(1) : "";

  const getReqStr = () => {
    if (reqStr?.length > length) {
      return reqStr?.slice(0, length) + "...";
    } else {
      return reqStr;
    }
  };

  return (
    <Tooltip title={reqStr}>
      <Typography className={"capitalize whte-spa-no-wrap " + className}>
        {getReqStr()}
      </Typography>
    </Tooltip>
  );
};

export default Custom3dotString;
