export const CardCss = {
  borderRadius: 1,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 1,
  paddingBottom: 1,
  marginTop: 2,
};

export const initSlot = {
  startTime: new Date(new Date().setHours(0, 0)),
  endTime: new Date(new Date().setHours(0, 0)),
  i: 0,
};
