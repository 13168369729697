import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hideLoader, showLoader } from "./loaderSlice";
import api from "../actions/api";
import { showSnackBar } from "./snackSlice";

export const getMedicationList = createAsyncThunk(
  "getMedicationList",
  async (url = "", thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).get(
        `/tablets${url}` //?search=something
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res.data.data;
      } else if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
      }
      thunkAPI.dispatch(hideLoader());
      return null;
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Error encountered!", type: "error" })
      );
    }
  }
);

export const getMedicalHistoryByCallId = createAsyncThunk(
  "getMedicalHistoryByCallId",
  async (callId, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).get(
        `/appoinments/${callId}/medical`
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res.data.data;
      } else if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
      }
      thunkAPI.dispatch(hideLoader());
      return null;
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Error encountered!", type: "error" })
      );
    }
  }
);

export const getMedicalHistoryByPetId = createAsyncThunk(
  "getMedicalHistoryByPetId",
  async ({ petId, url = "?type=all" }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).get(
        `/pet/${petId}/compliantSummary${url}`
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res?.data;
      } else if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
      }
      thunkAPI.dispatch(hideLoader());
      return null;
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Error encountered!", type: "error" })
      );
    }
  }
);

export const getPetMedicalHistory = createAsyncThunk(
  "getPetMedicalHistory",
  async (petId, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).get(
        `/pet/${petId}/compliantSummary?type=overview`
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res?.data?.data;
      } else if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
      }
      thunkAPI.dispatch(hideLoader());
      return null;
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      thunkAPI.dispatch(
        showSnackBar({ message: "Error encountered!", type: "error" })
      );
    }
  }
);

export const medicalHistorySlice = createSlice({
  name: "medicalHistory",
  initialState: {
    detail: null,
    list: [],
    medicationList: [],
    petMedicalHistory: {},
    medicalHistoryByPet: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMedicalHistoryByCallId.fulfilled, (state, action) => {
      state.detail = action.payload;
    });
    builder.addCase(getMedicationList.fulfilled, (state, action) => {
      state.medicationList = action.payload;
    });
    builder.addCase(getMedicalHistoryByPetId.fulfilled, (state, action) => {
      state.petMedicalHistory = action.payload;
    });
    builder.addCase(getPetMedicalHistory.fulfilled, (state, action) => {
      state.medicalHistoryByPet = action.payload;
    });
  },
});

export const {} = medicalHistorySlice.actions;

export default medicalHistorySlice.reducer;
