import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/CustomButton";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import {
  addInternalNoteByPetId,
  getPetDetailsById,
  getPetsCompliantSummary,
  updateInternalNoteByPetAndNoteId,
} from "../../../../redux/reducers/petSlice";

const InternalNotes = ({
  intrnalNoteModVsble,
  setIntrnalNoteModVsble,
  updatedApp,
  appoinment,
}) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState("");
  const [limitExceedErr, setLimitExceedErr] = useState(false);
  const comPetDet = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.pet
  );
  const petDetails = useSelector((state) => state?.pet?.petDetails);
  const apntId = appoinment?.appoinment?.appoimentId;
  const petId = appoinment?.appoinment?.petId;
  const petData = apntId ? comPetDet : petDetails;

  useEffect(() => {
    setNotes(petData?.internalNotes?.[0]?.notes?.trim());
  }, [petData]);

  const handleModClose = () => {
    setIntrnalNoteModVsble(false);
    setNotes("");
    setLimitExceedErr(false);
  };

  const handleUpdate = () => {
    if (apntId) {
      dispatch(getPetsCompliantSummary(updatedApp?.appoimentId ?? apntId));
    } else {
      dispatch(getPetDetailsById(petId));
    }
    handleModClose();
  };

  const handleSaveIntenalNote = () => {
    const reqObj = {
      petId: apntId ? updatedApp?.petId : petId,
      data: { notes: notes?.trim() },
    };
    if (petData?.internalNotes?.[0]) {
      reqObj.noteId = petData?.internalNotes?.[0]?.internalId;
      dispatch(updateInternalNoteByPetAndNoteId(reqObj)).then((res) => {
        if (res?.payload) handleUpdate();
      });
      return;
    }
    dispatch(addInternalNoteByPetId(reqObj)).then((res) => {
      if (res?.payload) handleUpdate();
    });
  };

  const handleChangeNote = (value) => {
    if (value?.length > 300) return setLimitExceedErr(true);
    setNotes(value);
    setLimitExceedErr(false);
  };

  return (
    <CustomModal
      open={intrnalNoteModVsble}
      onClose={handleModClose}
      header="Internal Notes"
      rightModal
      modalWidth={30}
    >
      <Grid container className="ph20">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt10">
          <CustomTextField
            placeholder="Write your notes"
            fullWidth
            handleChange={(e) => handleChangeNote(e?.target?.value)}
            value={notes}
            multiline
            rows={3}
          />
          {limitExceedErr && (
            <Typography color="error" className="ml5 mt5 fs14 txt-regular">
              *Maximum character count reached
            </Typography>
          )}
        </Grid>

        <div className="flex1-end mt20">
          <div>
            <CustomButton
              text={petData?.internalNotes?.[0]?.notes ? "Update" : "Save"}
              smallBtn
              onClick={handleSaveIntenalNote}
            />
          </div>
        </div>
      </Grid>
    </CustomModal>
  );
};

export default InternalNotes;
