export const ErrorStrings = {
  inValidEmail: "*We need domain details with @ to create a complete you!",
  emptyEmail: "*The email cannot be empty.",
  emptyPass: "*The password cannot be empty.",
  emptyMobile: "*The password cannot be empty.",
  emptyTitle: "*The Quiz Title cannot be empty",
  emptyCountry: "*Country cannot be empty",
  emptyDescription: "*The Description cannot be empty",
  emptyRules: "*The rules cannot be empty",
  emptyphone: "*Phone Cannot be empty",
  inValidPass: "*The password should be atleast 6 characters long.",
  inValidMobile: "*The mobile no should 10 characters.",
  inValidConfirmPass:
    "*We sure we know what we’re doing? Let’s try entering the same password twice in a row now!",
  emptyConfirmPass: "*The confirm password cannot be empty.",
  emptyFName: "*First Name is required.",
  fnameError: "*First Name should be atleast 2 characters long.",
  titleError: "*Quiz title should be atleast 2 characters long.",
  descriptionError: "*Description should be atleast 2 characters long.",
  rulesError: "*Rules should be atleast 5 characters long.",
  emptyLName: "*Last Name is required.",
  lnameError: "*Last Name should be atleast 2 characters long.",
  emptyUsername: "*Username is required.",
  countryError: "Country should be atleast 2 characters long",
  emptystate: "*State cannot be empty",
  stateError: "State should be atleast 2 characters long",
  emptycity: "*City cannot be empty",
  cityError: "City should be atleast 2 characters long",
  usernameError:
    "*Username should be atleast 5 characters long without any space.",
  emptyLocation: "*Location is required.",
  emptyAbout: "*About is required.",
  aboutError: "About should be atleast 5 character long.",
  emptyMoney: "*Prize money is required",
  emptyPhone: "*Phone no is required",
  phoneError: "*Phone is should valid one",
  emptyClinicName: "*The clinic name cannot be empty.",
  emptyAdministartorContact: "*The administartive contact cannot be empty.",
  emptyContactNumber: "*The contact number cannot be empty",
};
