import { uniq } from "lodash";
import moment from "moment";

export const getDateWithFormat = (date) => {
  const day = date.split("-").shift();
  const month = date.substring(3, 5);
  const year = date.split("-").pop();
  const monStr = moment(new Date(`${month}/${day}/${year}`)).format("MMM");
  return `${monStr} ${day}, ${year}`;
};

export const getTimeDiff = (t2, t1) => {
  const tdiff_in_ms = t2 - t1;
  const tdiff_in_sec = tdiff_in_ms / 1000;
  const tdiff_in_min = tdiff_in_sec / 60;
  const tdiff_in_hours = tdiff_in_min / 60;
  return {
    hh: tdiff_in_hours,
    mm: tdiff_in_min,
  };
};

export const getExpiryTime = (expiryTime) => {
  const currentTime = new Date();
  const expiryTimeInMs = expiryTime.getTime();
  expiryTime.setTime(expiryTimeInMs);
  const tdiff = getTimeDiff(expiryTime.getTime(), currentTime.getTime());
  const expiresIn = {
    hh: Math.floor(tdiff.hh),
    mm: Math.floor(tdiff.mm % 60),
    ss: 0,
  };
  if (expiresIn.mm <= 0) return "Expired";
  return `${expiresIn.hh}h ${expiresIn.mm}m`;
};

export const disableBtn = (date, minutes = 15) => {
  const today = new Date();
  const dbDate = new Date(date);
  if (dbDate.getTime() < today.getTime()) return false;
  if (dbDate.getTime() > today.getTime()) {
    if (
      dbDate.getFullYear() === today.getFullYear() &&
      dbDate.getMonth() === today.getMonth() &&
      dbDate.getDate() === today.getDate()
    ) {
      if (dbDate.getHours() >= today.getHours()) {
        const minDiff = Math.floor(Math.abs(dbDate - today) / 1000 / 60);
        if (minDiff <= minutes) return false;
        return true;
      }
      return true;
    }
    return true;
  }
  return false;
};

export const getDateList = (numberOfDays = 7) => {
  const today = new Date();
  return Array.from({ length: numberOfDays }, (_, i) => {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    return date.toISOString().split("T")[0];
  });
};

export function createTimeSlots(startTime, endTime, duration) {
  // Helper function to pad single digit numbers with a leading zero
  function pad(num) {
    return num < 10 ? "0" + num : num;
  }

  // Helper function to format a Date object to HH:mm string
  function formatTime(date) {
    return pad(date.getHours()) + ":" + pad(date.getMinutes());
  }

  // Parse the start and end times
  const startParts = startTime?.split(":")?.map(Number);
  const endParts = endTime?.split(":")?.map(Number);

  // Create Date objects for the start and end times
  const start = new Date();
  start.setHours(startParts[0], startParts[1], 0, 0);

  const end = new Date();
  end.setHours(endParts[0], endParts[1], 0, 0);

  // Calculate the duration in milliseconds
  const durationMs = duration * 60 * 1000;

  // Generate the time slots
  const slots = [];
  let current = new Date(start);
  while (current <= end) {
    slots.push(formatTime(current));
    current = new Date(current.getTime() + durationMs);
  }

  return slots;
}

export function isTimeBetween(time, startTime) {
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [hour, minute] = time.split(":").map(Number);

  const start = startHour * 60 + startMinute;
  const end = startHour * 60 + 59;
  const date = hour * 60 + minute;

  if (
    (start <= date && date <= end) ||
    date === start ||
    (startHour === hour && startMinute === minute) ||
    time === startTime
  ) {
    return true;
  }
  return false;
}

export const getBorderColor = (status) => {
  switch (status) {
    case "paid" || "Paid":
      return "#80AAD3";
    case "unpaid" || "UnPaid" || "Unpaid":
      return "#ff754a";
    default:
      return "transparent";
  }
};

export function splitTimesByInterval(
  startDate,
  startTime,
  endDate,
  endTime,
  numIntervals
) {
  // Ensure date strings are in YYYY-MM-DD format
  const startDateFormatted = new Date(`${startDate}T${startTime}:00`);
  const endDateFormatted = new Date(`${endDate}T${endTime}:00`);

  // Calculate the total duration in milliseconds
  const totalDuration =
    endDateFormatted.getTime() - startDateFormatted.getTime();

  // Check for invalid input (negative duration)
  if (totalDuration <= 0) {
    throw new Error(
      "Invalid input: Start date/time must be before end date/time"
    );
  }

  // Calculate the interval length in milliseconds, considering potential remainder
  const intervalLength = Math.floor(totalDuration / numIntervals);
  const remainder = totalDuration % numIntervals;

  const intervals = [];

  // Loop through intervals, distributing remainder evenly
  for (let i = 0; i < numIntervals; i++) {
    const startTime = new Date(
      startDateFormatted.getTime() +
        i * intervalLength +
        Math.floor((remainder * i) / numIntervals)
    );
    const endTime = new Date(
      startTime.getTime() +
        intervalLength +
        (i === numIntervals - 1 ? remainder % numIntervals : 0)
    );

    intervals.push({
      startDate: moment(new Date(startTime)).format("YYYY-MM-DD"),
      startTime: moment(new Date(startTime)).format("HH:mm"),
      endDate: moment(new Date(endTime)).format("YYYY-MM-DD"),
      endTime: moment(new Date(endTime)).format("HH:mm"),
    });
  }

  return intervals;
}

export const getTimeFormat = (time) => {
  return moment(new Date(time)).format("HH:mm");
};

export const getDateFormat = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};

export function getWeekStartEndDate() {
  const today = new Date();

  // Get the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const dayOfWeek = today.getDay();

  // Calculate the start of the week (Sunday)
  const weekStart = new Date(today);
  weekStart.setDate(today.getDate() - dayOfWeek);

  // Calculate the end of the week (Saturday)
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 6);

  return { startDate: weekStart, endDate: weekEnd };
}

export function getUpcoming7Dates() {
  const dates = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    dates.push(date);
  }
  return dates;
}

function getSlots(startDateISO, endDateISO, durationHours) {
  const slots = [];
  const durationMilliseconds = durationHours * 60 * 60 * 1000;
  let currentDate = new Date(startDateISO);

  const endDate = new Date(endDateISO);

  while (currentDate < endDate) {
    const nextSlot = new Date(currentDate.getTime() + durationMilliseconds);

    if (nextSlot > endDate) {
      break;
    }

    slots.push({
      startDate: getDateFormat(currentDate),
      startTime: getTimeFormat(currentDate),
      endDate: getDateFormat(nextSlot),
      endTime: getTimeFormat(nextSlot),
    });

    currentDate = nextSlot;
  }

  return slots;
}

export function getDateAndTimeList(
  strtDate,
  enDate,
  startTime,
  endTime,
  duration //duration in hours
) {
  const [hr, min] = getTimeFormat(startTime)?.split(":");
  const [enHr, enMin] = getTimeFormat(endTime)?.split(":");
  const startDate = new Date(
    new Date(getDateFormat(strtDate)).setHours(hr, min)
  );
  const endDate = new Date(
    new Date(getDateFormat(enDate)).setHours(
      Number(enHr) + Number(duration),
      enMin
    )
  );

  let reqDates = [];

  while (startDate <= endDate) {
    const reqEndTime = new Date(
      new Date(getDateFormat(startDate)).setHours(
        Number(enHr) + Number(duration),
        enMin
      )
    );
    const times = getSlots(
      startDate.toISOString(),
      reqEndTime.toISOString(),
      duration
    );
    reqDates = [...reqDates, ...times];
    startDate.setDate(startDate.getDate() + 1);
  }
  return reqDates;
}

export function validateTimeSlots(clinicHourList, duartion) {
  return clinicHourList?.every((day) => {
    return day?.slots?.every((slot) => {
      const startTime = new Date(slot?.startTime);
      const endTime = new Date(slot?.endTime);
      const timeDiff = getTimeDiff(endTime, startTime);
      return endTime > startTime && timeDiff?.mm >= Number(duartion);
    });
  });
}

export const getAllDatesFromArray = (list) => {
  const dates = [];
  list?.forEach((li) => {
    const startDate = new Date(li?.date);
    const endDate = new Date(li?.endDate);

    for (const d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      dates.push(d.toISOString().split("T")[0]);
    }
  });
  return uniq(dates);
};

export const getUpcomingSlotsWithoutBook = (slots) => {
  const currTime = moment(new Date()).format("HH:mm");
  return slots?.filter((item) => item?.time > currTime && item?.book !== "Y");
};

export const getTodayWithYMDFormat = () => {
  return moment(new Date()).format("YYYY-MM-DD");
};

export const getKey = () => {
  return (Math.random() + 1).toString(36).substring(7);
};
