export const AppColors = {
  appPrimary: "#0054A6",
  white: "#fff",
  black: "#000",
  yellowBtn: "#FFC225",
  redBtn: "#F74444",
  grayBtn: "#A9A9A9",
  yellowPower: "#FFD700",
  gray: "#899499",
  grayBtn2: "#C9C9C9",
  gray2: "#A5B4CB",
  gray3: "#828282",
  gray4: "#00000066",
  gray5: "#667085",
  gray6: "#E2E2E252",
  black5: "#00000040",
  btnWhite: "#616161",
  btnGrayBorder: "#DBDADA",
  switchGray: "#D9D9D9",
  switchGreen: "#03992D",
  switchGreen2: "#76ee59",
  switchGray2: "#0000001A",
  snackGreen: "#2e7d32",
  green: "#10C200",
  snackError: "#d32f2f",
  snackInfo: "#0288d1",
  snackWarn: "#ed6c02",
  appYellow: "#FDB913",
  topSelectdBtn: "#F4FFF3",
  tableGrayHead: "#606E80B2",
  tableGrey: "#9098a2",
  red: "#FF1D1DCC",
  lightPink: "#E3CECE52",
  lightBlue: "#EFF6FCDE",
};
