import { Card, CardMedia, Grid, Pagination, Typography } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import { useEffect, useState } from "react";
import moment from "moment";
import viewImg from "../../../../assets/images/png/view.png";
import { CardCss } from "../../../../util/object";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomButton from "../../../../components/CustomButton";
import CustomTable from "../../../../components/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { getMedicalHistoryByPetId } from "../../../../redux/reducers/medicalHistorySlice";
import { AppColors } from "../../../../util/AppColors";

const preventTblHeader = ["type", "name", "date", "remark", "file", "status"];

const diagTblHeader = [
  "type",
  "dueDate",
  "remarks",
  "attachement",
  "statusDig",
];

const MedicalHistory = ({ petId }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [isPreview, setPreview] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [activeTab, setActiveTab] = useState("ClinicNotes");
  const medicalHistory = useSelector(
    (state) => state?.medicalHistory?.petMedicalHistory
  );
  const petData = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.pet
  );
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const [tblPrevData, setTblPrevData] = useState([]);
  const [tblDiagData, setTblDiagData] = useState([]);
  const itemsPerLoad = 5;
  const [page, setPage] = useState(1);
  const [filteredList, setFilteredList] = useState([]);

  const clinicalNotes = selectedHistory?.clinicalNotes?.[0] ?? null;
  const prescription =
    selectedHistory?.prescription?.length > 0
      ? selectedHistory?.prescription?.[0]
      : null;
  const preventive = selectedHistory?.prevetive;
  const diagnostics = selectedHistory?.diagnostics;

  const defaultUrl = `?type=all&page=1&itemSize=${itemsPerLoad}`;

  useEffect(() => {
    dispatch(getMedicalHistoryByPetId({ petId, url: defaultUrl }));
  }, []);

  useEffect(() => {
    const reqData = preventive
      ?.map((item) => {
        return item?.preventiveDetails?.map((detail) => ({
          preventiveId: item?.preventiveId,
          petId: item?.petId,
          remark: item?.remark,
          status: item?.status,
          id: detail?.id,
          type: detail?.type,
          name: detail?.name,
          date: detail?.date,
          file: detail?.file,
        }));
      })
      .flat();
    setTblPrevData(reqData);
  }, [preventive]);

  useEffect(() => {
    const reqData = diagnostics?.map((diag, i) => {
      return {
        ...diag,
        remarks: diag?.remark,
        type: <strong>{diag?.type}</strong>,
        dueDate: moment(diag?.dueDate).format("DD MMM YYYY"),
        attachment: (
          <span
            className={diag?.file?.length > 0 ? "blue2 cursor" : "gray7"}
            // onClick={() => {
            //   if (diag?.file?.length > 0) {
            //     setAttachment(diag?.file);
            //     setAttachModVisible(true);
            //   }
            // }}
          >
            {diag?.file?.length > 0 ? "1 Attachment" : "Nil"}
          </span>
        ),
        statusDig: (
          <div className="flex-row">
            <div
              className={
                diag?.status === "Completed"
                  ? "completed"
                  : diag?.status === "Pending"
                  ? "pending"
                  : "overdue"
              }
            >
              {diag?.status}
            </div>
          </div>
        ),
      };
    });
    setTblDiagData(reqData);
  }, [diagnostics]);

  useEffect(() => {
    const reqList = medicalHistory?.data
      ?.filter(
        (med) =>
          med?.appointmentStatus === "completed" ||
          med?.appointmentStatus === "cancelled"
      )
      ?.filter(
        (med) =>
          med?.appointmentStatus
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase()) ||
          med?.medicalHistory?.[0]?.problemType
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase()) ||
          med?.doctorName?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
    setFilteredList(reqList);
  }, [medicalHistory?.data, searchValue]);

  const handlePreModClose = () => {
    setPreview(false);
    setActiveTab("ClinicNotes");
    setSelectedHistory(null);
  };

  const handleChangePage = (e, selectedPage) => {
    dispatch(
      getMedicalHistoryByPetId({
        petId,
        url: `?type=all&page=${selectedPage}&itemSize=${itemsPerLoad}`,
      })
    );
    setPage(selectedPage);
  };

  const getPresItems = (type, count) => {
    const filteredList = prescription?.prescriptionDetails?.filter(
      (pd) => pd?.type === type
    );
    if (count) return filteredList?.length;

    return filteredList?.length > 0 ? (
      filteredList?.map((li, i) => {
        return (
          <div className="gray-back-con">
            <div className="flex-row">
              <div className="w85Per">
                <div className="flex-row">
                  <div className="font-bold fs12 black">{li?.name}</div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="txt-regular fs12 black4 ml5">
                      {li?.dosage ?? li?.does}{" "}
                      {type === "medications" ? "tablet" : "spoon"}
                    </div>
                  </div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="txt-regular fs12 black4 ml5">
                      {li?.duration} days
                    </div>
                  </div>
                  <div className="flex-row flex-center ml10">
                    <div className="gray-dot" />
                    <div className="txt-regular fs12 black4 ml5">
                      {li?.meal === "afterMeal" ? "After Meal" : "Before Meal"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="blue-back-con fs12 font-medium">
                {`${li?.morning ? "1" : "0"}-${li?.afternoon ? "1" : "0"}-${
                  li?.night ? "1" : "0"
                }`}
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="no-rec">No records available</div>
    );
  };

  return (
    <>
      <div className="scroll-80vh w100Per">
        <Grid container className="back-white">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="ph20">
            <div className="flex-center">
              <div className="w75Per">
                <CustomTextField
                  search
                  placeholder="Search"
                  fullWidth
                  backgroundColor={AppColors.lightPink}
                  value={searchValue}
                  handleChange={(e) => setSearchValue(e?.target?.value)}
                />
              </div>
            </div>
            <div className="pb100 w100Per">
              {filteredList?.map((medHis, i) => {
                return (
                  <div
                    className="flex-row-ali-cen mv10"
                    key={i + medHis?.appointmentTime}
                  >
                    <div className="w20Per">
                      <div className="back-img">
                        <div className="flex-center h100">
                          <div className="flex-column flex-center">
                            <Typography className="black2 fs10 font-bold">
                              {moment(medHis?.date).format("DD MMM")}
                            </Typography>
                            <Typography className="black2 fs10 font-bold mt5">
                              {moment(medHis?.date).format("YYYY")}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w60Per">
                      <Card sx={CardCss} className="inner-cards h110">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="mt10"
                          >
                            <div className="card-top-color card-top-blue-color" />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="ph10">
                              <div className="flex-row">
                                <div className="flex-start">
                                  <div className="flex-column w10Per">
                                    <div className="h50">
                                      {medHis?.doctorImage ? (
                                        <CardMedia
                                          image={medHis?.doctorImage}
                                          className="img-h40"
                                        />
                                      ) : (
                                        <div className="flex-center img-hw40 back-gray2">
                                          <Typography className="font-bold fs30 white-color capitalize">
                                            {medHis?.doctorName?.[0]}
                                          </Typography>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-column">
                                    <div className="h50 ml30 w100Per">
                                      <Typography className="font-bold fs14 black capitalize">
                                        Dr. {medHis?.doctorName}
                                      </Typography>
                                      <Typography className="gray7 fs14 font-medium mt5 capitalize">
                                        {
                                          medHis?.medicalHistory?.[0]
                                            ?.problemType
                                        }
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex1-end">
                                  {medHis?.appointmentStatus === "completed" ? (
                                    <img
                                      src={viewImg}
                                      alt=""
                                      className="img-hw40 cursor"
                                      onClick={() => {
                                        setPreview(true);
                                        setSelectedHistory(medHis);
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="flex-row-ali-cen mt5">
                                <div className="flex-start">
                                  <div className="card-light-blue-back card-time flex-center">
                                    <Typography className="txt-regular card-blue2 fs12">
                                      {moment(medHis?.appointmentTime).format(
                                        "HH:mm"
                                      )}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="flex1-end">
                                  <div
                                    className={`br5 ph10 pv5 ${
                                      medHis?.appointmentType === "Physical"
                                        ? "card-con-blue-back"
                                        : "virtual-bg-color"
                                    }`}
                                  >
                                    <Typography className="header fs12 white-color">
                                      {medHis?.appointmentType}
                                    </Typography>
                                  </div>
                                  <div
                                    className={`br5 ph10 pv5 ${
                                      medHis?.appointmentStatus === "completed"
                                        ? "green-back2"
                                        : "org-back2"
                                    } ml10`}
                                  >
                                    <Typography
                                      className={`header fs12 ${
                                        medHis?.appointmentStatus ===
                                        "completed"
                                          ? "green2"
                                          : "orange4"
                                      }`}
                                    >
                                      {medHis?.appointmentStatus === "completed"
                                        ? "Completed"
                                        : "Cancelled"}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </div>
                  </div>
                );
              })}
              {Math.ceil(medicalHistory?.totalRecords / itemsPerLoad) > 1 ? (
                <div className="flex-end">
                  <Pagination
                    count={Math.ceil(
                      medicalHistory?.totalRecords / itemsPerLoad
                    )}
                    variant="outlined"
                    color="primary"
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      <CustomModal
        open={isPreview}
        onClose={handlePreModClose}
        header=""
        modal
        modalWidth={70}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="back-white">
              <div className="flex-row-center">
                <div>
                  <CustomButton
                    text="Clinical Notes"
                    onClick={() => setActiveTab("ClinicNotes")}
                    tabOutlined
                    yellowBtn={activeTab === "ClinicNotes"}
                  />
                </div>

                <div className="w5Per">
                  <div className="flex-center">
                    <div className="ver-bar-h30" />
                  </div>
                </div>

                <div>
                  <CustomButton
                    text="Preventive"
                    onClick={() => setActiveTab("Preventive")}
                    tabOutlined
                    yellowBtn={activeTab === "Preventive"}
                  />
                </div>

                <div className="w5Per">
                  <div className="flex-center">
                    <div className="ver-bar-h30" />
                  </div>
                </div>

                <div>
                  <CustomButton
                    text="Prescription"
                    onClick={() => setActiveTab("Prescription")}
                    tabOutlined
                    yellowBtn={activeTab === "Prescription"}
                  />
                </div>

                <div className="w5Per">
                  <div className="flex-center">
                    <div className="ver-bar-h30" />
                  </div>
                </div>

                <div>
                  <CustomButton
                    text="Diagnostics"
                    onClick={() => setActiveTab("Diagnostics")}
                    tabOutlined
                    yellowBtn={activeTab === "Diagnostics"}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="scroll-70vh w100Per">
          {activeTab === "Preventive" || activeTab === "Diagnostics" ? (
            <CustomTable
              grey
              preventive={activeTab === "Preventive"}
              columns={
                activeTab === "Preventive" ? preventTblHeader : diagTblHeader
              }
              datas={activeTab === "Preventive" ? tblPrevData : tblDiagData}
            />
          ) : null}
          {activeTab === "ClinicNotes" &&
            (clinicalNotes ? (
              <>
                <div className="mt20">
                  <div className="flex-row-ali-cen">
                    <div className="heading-fw600 fs14 black">
                      Key Identification/Diagnosis
                    </div>
                    {clinicalNotes?.tag && (
                      <Typography className="font-medium fs10 card-time blu-back white-color ml10">
                        {clinicalNotes?.tag}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className="mt20">
                  <div className="flex-row-between-align-center">
                    <div className="heading-fw600 fs14 black">Subjective</div>
                  </div>
                  <div className="text400 mt10 p15 back-white cli-not-box">
                    {clinicalNotes?.subjective}
                  </div>
                </div>
                <div className="mt20">
                  <div className="flex-row-between-align-center">
                    <div className="heading-fw600 fs14 black">Objective</div>
                  </div>
                  <div className="text400 mt10 p15 back-white cli-not-box">
                    {clinicalNotes?.objective}
                  </div>
                </div>
                <div className="mt20">
                  <div className="flex-row-between-align-center">
                    <div className="heading-fw600 fs14 black">Assessment</div>
                  </div>
                  <div className="text400 mt10 p15 back-white cli-not-box">
                    {clinicalNotes?.assessment}
                  </div>
                </div>
                <div className="mt20">
                  <div className="flex-row-between-align-center">
                    <div className="heading-fw600 fs14 black">Plan</div>
                  </div>
                  <div className="text400 mt10 p15 back-white cli-not-box">
                    {clinicalNotes?.plan}
                  </div>
                </div>
                <div className="mt20">
                  <div className="flex-row-between-align-center">
                    <div className="heading-fw600 fs14 black">Conclusion</div>
                  </div>
                  <div className="text400 mt10 p15 back-white cli-not-box">
                    {clinicalNotes?.conclusion}
                  </div>
                </div>
              </>
            ) : (
              <div className="no-rec h100">No records available</div>
            ))}
          {activeTab === "Prescription" &&
            (prescription ? (
              <div className="ph15Per">
                <div className="blue-box-examD mt20">
                  <div className="flex-row">
                    <div className="w33Per">
                      <div className="flex-column">
                        <div className="flex-row mt10">
                          <div className="font-bold fs14 blue-color">
                            Pet Name:
                          </div>
                          <div className="font-bold fs14 black capitalize ml5">
                            {petData?.petName}
                          </div>
                        </div>
                        <div className="flex-row mv5">
                          <div className="font-bold fs14 blue-color">
                            Breed:
                          </div>
                          <div className="txt-regular fs14 black capitalize ml5">
                            {prescription?.breed}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w33Per">
                      <div className="flex-column">
                        <div className="flex-row mt10">
                          <div className="font-bold fs14 blue-color">
                            Vet Name:
                          </div>
                          <div className="font-bold fs14 black ml5 capitalize">
                            Dr. {prescription?.doctorName}
                          </div>
                        </div>
                        <div className="flex-row mv5">
                          <div className="font-bold fs14 blue-color">Date:</div>
                          <div className="txt-regular fs14 black ml5">
                            {prescription
                              ? moment(prescription?.createdDate).format(
                                  "DD MMM YYYY"
                                )
                              : moment().format("DD MMM YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w33Per">
                      <div className="flex-end">
                        <img
                          alt=""
                          src={
                            profile?.image ??
                            require("../../../../assets/images/png/applologo.png")
                          }
                          className="img-h50"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-hor-split mv10" />
                  {getPresItems("medications", true) > 0 ? (
                    <>
                      <div className="font-bold fs14 blue-color mv10">
                        Medications
                      </div>
                      {getPresItems("medications")}
                    </>
                  ) : null}
                  {getPresItems("supplements", true) > 0 ? (
                    <>
                      <div className="font-bold fs14 blue-color mv10">
                        Supplements
                      </div>
                      {getPresItems("supplements")}
                    </>
                  ) : null}
                  <div className="font-bold fs14 blue-color mv10">Advice</div>
                  <CustomTextField
                    fullWidth
                    multiline
                    placeholder="Advice"
                    rows={3}
                    value={prescription?.remark ?? ""}
                  />
                  <div className="flex-row mv20">
                    <img
                      src={require("../../../../assets/images/png/qrcodedown.png")}
                      alt=""
                      className="img-h50"
                    />
                    <div className="blu-back wh50 flex-center">
                      <img
                        src={require("../../../../assets/images/png/VetInstantLogo.png")}
                        alt=""
                        className="img-h40"
                      />
                    </div>
                    <div className="flex-center">
                      <div className="font-bold fs14 ml20">
                        Scan to download our app
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="no-rec h100">No records available</div>
            ))}
        </div>
      </CustomModal>
    </>
  );
};

export default MedicalHistory;
