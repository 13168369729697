import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Container, FormHelperText, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import Checkbox from "../../../components/CustomCheckbox";
import CustomSwitch from "../../../components/CustomSwitch";
import CustomTextField from "../../../components/CustomTextField";
import {
  createClinicSlots,
  getPhyAvailabilityByVetId,
  getVirAvailabilityByVetId,
} from "../../../redux/reducers/clinicSlice";
import {
  deleteVetHolidayById,
  getVetHolidaysById,
  storeVetHolidaysById,
} from "../../../redux/reducers/doctorSlice";
import { getGlobalDays } from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import { weekArray } from "../../../util/arrayList";
import { validateTimeSlots } from "../../../util/function";
import { initSlot } from "../../../util/object";
import VetAndUpcomingAppointments from "../../CommonScreens/VetAndUpcomingAppointments/VetAndUpcomingAppointments";

const initHolidayValues = {
  startDate: new Date(),
  endDate: new Date(),
  remark: "",
};
const initDurationValues = { physical: 15, virtual: 15 };

const VetManageAvailability = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [phyOpen, setPhyOpen] = useState(false);
  const [virOpen, setVirOpen] = useState(false);
  const [clinicSchduleEnabled, setClinicSchduleEnabled] = useState(false);
  const [durationValues, setDurationValues] = useState(initDurationValues);
  const [holiday, setHoliday] = useState([]);
  const [phyDayValues, setPhyDayValues] = useState(weekArray);
  const [virDayValues, setVirDayValues] = useState(weekArray);
  const clinicHours = useSelector((state) => state.vet.globalDays);
  const holidays = useSelector((state) => state?.doctor?.holidays);
  const vetDetails = useSelector((state) => state?.clinic?.vet);
  const vetId = location?.state?.vetId;
  const { vetPhyAvailability, vetVirAvailability } = useSelector(
    (state) => state?.clinic
  );
  const [clinicHourList, setClinicHourList] = useState([]);
  const [holidayError, setHolidayError] = useState(false);
  const [phyEditEnabled, setPhyEditEnabled] = useState(false);
  const [virEditEnabled, setVirEditEnabled] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [virNoSlotErr, setVirNoSlotErr] = useState(false);
  const [phyNoSlotErr, setPhyNoSlotErr] = useState(false);
  const [virTimeErr, setVirTimeErr] = useState(false);
  const [phyTimeErr, setPhyTimeErr] = useState(false);

  useEffect(() => {
    dispatch(getVetHolidaysById(vetId));
    dispatch(getGlobalDays());
  }, []);

  useEffect(() => {
    if (checkTypeIncluded("Virtual")) {
      dispatch(getVirAvailabilityByVetId(vetId));
    }
    if (checkTypeIncluded("Physical")) {
      dispatch(getPhyAvailabilityByVetId(vetId));
    }
  }, [vetDetails]);

  useEffect(() => {
    handlePrevAvailability();
  }, [vetPhyAvailability, vetVirAvailability]);

  useEffect(() => {
    getRequiredDateList();
  }, [clinicHours]);

  const getRequiredDateList = () => {
    if (clinicHours?.length > 0) {
      const reqArr = weekArray?.map((day) => {
        const dbDay = clinicHours?.find((pa) => pa?.day === day?.value);
        if (dbDay) {
          const reqTimes = dbDay?.times?.map((tm, i) => {
            const [hour, min] = tm?.startTime?.split(":");
            const [endHour, endMin] = tm?.endTime?.split(":");
            return {
              startTime: new Date(new Date().setHours(hour, min)),
              endTime: new Date(new Date().setHours(endHour, endMin)),
              i,
            };
          });
          return {
            ...day,
            isSelected: dbDay?.status,
            slots: reqTimes,
          };
        }
        return day;
      });
      setClinicHourList(reqArr);
    }
  };

  const checkTypeIncluded = (type) => {
    return vetDetails?.consulationType?.includes(type);
  };

  const handlePrevAvailability = () => {
    if (vetPhyAvailability?.length > 0) {
      const reqArr = weekArray?.map((day) => {
        const dbDay = vetPhyAvailability?.find((pa) => pa?.day === day?.value);
        if (dbDay) {
          const reqTimes = dbDay?.times?.map((tm, i) => {
            const [hour, min] = tm?.startTime?.split(":");
            const [endHour, endMin] = tm?.endTime?.split(":");
            return {
              startTime: new Date(new Date().setHours(hour, min)),
              endTime: new Date(new Date().setHours(endHour, endMin)),
              i,
            };
          });
          return {
            ...day,
            isSelected: dbDay?.status,
            slots: reqTimes,
          };
        }
        return day;
      });
      setPhyDayValues(reqArr);
      setPhyOpen(true);
    }
    if (vetVirAvailability?.length > 0) {
      const reqArr = weekArray?.map((day) => {
        const dbDay = vetVirAvailability?.find((pa) => pa?.day === day?.value);
        if (dbDay) {
          const reqTimes = dbDay?.times?.map((tm, i) => {
            const [hour, min] = tm?.startTime?.split(":");
            const [endHour, endMin] = tm?.endTime?.split(":");
            return {
              startTime: new Date(new Date().setHours(hour, min)),
              endTime: new Date(new Date().setHours(endHour, endMin)),
              i,
            };
          });
          return {
            ...day,
            isSelected: dbDay?.status,
            slots: reqTimes,
          };
        }
        return day;
      });
      setVirDayValues(reqArr);
      setVirOpen(true);
    }
  };

  const handleChangesDurationValues = (e) => {
    setDurationValues({
      ...durationValues,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const handleChangeHoliday = (e) => {
    const { name, value } = e?.target;
    const reqObj = { ...holiday?.[0], [name]: value };
    if (name === "startDate") {
      reqObj.endDate =
        new Date(value) > new Date(holiday?.[0]?.endDate)
          ? value
          : holiday?.[0]?.endDate;
      if (new Date(value) > new Date(reqObj.endDate)) {
        setDateErr(true);
      } else {
        setDateErr(false);
      }
    }
    if (name === "endDate") {
      if (new Date(holiday?.[0]?.startDate) > new Date(value)) {
        setDateErr(true);
      } else {
        setDateErr(false);
      }
    }
    if (name === "remark") {
      setHolidayError(value?.length > 0 ? false : true);
    }
    setHoliday([reqObj]);
  };

  const handlePhysicalDaySelect = (ind, value) => {
    const reqValues = phyDayValues?.map((pd, i) => {
      if (i === ind) return { ...pd, isSelected: value };
      return pd;
    });
    setPhyNoSlotErr(!reqValues?.some((rv) => rv?.isSelected));
    setPhyDayValues(reqValues);

    if (
      validateTimeSlots(
        reqValues?.filter((rv) => rv?.isSelected),
        durationValues?.physical
      )
    ) {
      setVirTimeErr(false);
    } else {
      setVirTimeErr(true);
    }
  };

  const handleVirtualDaySelect = (ind, value) => {
    const reqValues = virDayValues?.map((pd, i) => {
      if (i === ind) return { ...pd, isSelected: value };
      return pd;
    });
    setVirNoSlotErr(!reqValues?.some((rv) => rv?.isSelected));
    setVirDayValues(reqValues);
    if (
      validateTimeSlots(
        reqValues?.filter((rv) => rv?.isSelected),
        durationValues?.virtual
      )
    ) {
      setVirTimeErr(false);
    } else {
      setVirTimeErr(true);
    }
  };

  const handleAddDateRow = (i) => {
    const reqValue = phyDayValues?.map((pd, ind) => {
      if (i === ind) {
        return {
          ...pd,
          slots: [...pd?.slots, { ...initSlot, i: pd?.slots?.length }],
        };
      }
      return pd;
    });
    setPhyDayValues(reqValue);
  };

  const handleVirAddDateRow = (i) => {
    const reqValue = virDayValues?.map((vd, ind) => {
      if (i === ind) {
        return {
          ...vd,
          slots: [...vd?.slots, { ...initSlot, i: vd?.slots?.length }],
        };
      }
      return vd;
    });
    setVirDayValues(reqValue);
  };

  const handleDeleteDateRow = (i, ind) => {
    const reqValue = phyDayValues?.map((pd, index) => {
      if (i === index) {
        return {
          ...pd,
          slots: pd?.slots?.filter((slt, index2) => index2 !== ind),
        };
      }
      return pd;
    });
    setPhyDayValues(reqValue);
  };

  const handleVirDeleteDateRow = (i, ind) => {
    const reqValue = virDayValues?.map((vd, index) => {
      if (i === index) {
        return {
          ...vd,
          slots: vd?.slots?.filter((slt, index2) => index2 !== ind),
        };
      }
      return vd;
    });
    setVirDayValues(reqValue);
  };

  const handleChangeDateValue = (name, value, mainInd, secInd) => {
    const reqValue = phyDayValues?.map((pd, i) => {
      if (i === mainInd) {
        return {
          ...pd,
          slots: pd?.slots?.map((slt, ind) => {
            if (ind === secInd) {
              return { ...slt, [name]: value };
            }
            return slt;
          }),
        };
      }
      return pd;
    });
    setPhyDayValues(reqValue);
    if (
      validateTimeSlots(
        reqValue?.filter((rv) => rv?.isSelected),
        durationValues?.physical
      )
    ) {
      setPhyTimeErr(false);
    } else {
      setPhyTimeErr(true);
    }
  };

  const handleVirChangeDateValue = (name, value, mainInd, secInd) => {
    const reqValue = virDayValues?.map((vd, i) => {
      if (i === mainInd) {
        return {
          ...vd,
          slots: vd?.slots?.map((slt, ind) => {
            if (ind === secInd) {
              return { ...slt, [name]: value };
            }
            return slt;
          }),
        };
      }
      return vd;
    });
    setVirDayValues(reqValue);
    if (
      validateTimeSlots(
        reqValue?.filter((rv) => rv?.isSelected),
        durationValues?.virtual
      )
    ) {
      setVirTimeErr(false);
    } else {
      setVirTimeErr(true);
    }
  };

  const handleUpdateSlot = (type) => {
    const reqList = type === "physical" ? phyDayValues : virDayValues;

    let data = {};
    if (clinicSchduleEnabled && type === "physical") {
      if (
        validateTimeSlots(
          clinicHourList?.filter((ch) => ch?.isSelected),
          durationValues?.physical
        )
      ) {
        setPhyTimeErr(false);
        data = {
          type,
          dayRequest: clinicHourList?.map((li) => ({
            day: li?.day?.toLowerCase(),
            times: li?.slots?.map((slt) => ({
              startTime: moment(new Date(slt?.startTime)).format("HH:mm"),
              endTime: moment(new Date(slt?.endTime)).format("HH:mm"),
            })),
            status: li?.isSelected,
          })),
          duration:
            type === "physical"
              ? durationValues?.physical
              : durationValues?.virtual,
        };
      } else {
        return setPhyTimeErr(true);
      }
    } else {
      if (
        validateTimeSlots(
          reqList?.filter((rl) => rl?.isSelected),
          type === "physical"
            ? durationValues?.physical
            : durationValues?.virtual
        )
      ) {
        type === "physical" ? setPhyTimeErr(false) : setVirTimeErr(false);
        data = {
          type,
          dayRequest: reqList?.map((li) => ({
            day: li?.value,
            times: li?.slots?.map((slt) => ({
              startTime:
                moment(new Date(slt?.startTime)).format("HH:mm") ?? "00:00",
              endTime:
                moment(new Date(slt?.endTime)).format("HH:mm") ?? "00:00",
            })),
            status: li?.isSelected,
          })),
          duration:
            type === "physical"
              ? durationValues?.physical
              : durationValues?.virtual,
        };
      } else {
        return type === "physical" ? setPhyTimeErr(true) : setVirTimeErr(true);
      }
    }
    if (data?.dayRequest?.some((dr) => dr?.status)) {
      dispatch(createClinicSlots({ vetId, data })).then((res) => {
        if (res?.meta?.requestStatus === "fulfilled") {
          dispatch(getVirAvailabilityByVetId(vetId));
          dispatch(getPhyAvailabilityByVetId(vetId));
          setClinicSchduleEnabled(false);
          setPhyEditEnabled(false);
          setVirEditEnabled(false);
        }
      });
    } else {
      if (type === "physical") setPhyNoSlotErr(true);
      if (type === "virtual") setVirNoSlotErr(true);
    }
  };

  const handleAddHoliday = () => {
    if (holiday?.length === 0) {
      setHoliday([initHolidayValues]);
    }
  };

  const handleSaveHoliday = () => {
    const reqHol = holiday?.[0];
    if (dateErr || reqHol?.remark?.length === 0 || !reqHol?.remark) {
      if (dateErr) setDateErr(true);
      return setHolidayError(true);
    }
    const data = {
      holliday: reqHol?.remark,
      date: moment(new Date(reqHol?.startDate)).format("YYYY-MM-DD"),
      endDate: moment(new Date(reqHol?.endDate)).format("YYYY-MM-DD"),
    };
    dispatch(storeVetHolidaysById({ vetId, data })).then((res) => {
      if (res?.payload) {
        setHoliday([]);
        dispatch(getVetHolidaysById(vetId));
      }
    });
  };

  const handleDelHoliday = (holId) => {
    dispatch(deleteVetHolidayById(holId)).then((res) => {
      if (res?.payload) dispatch(getVetHolidaysById(vetId));
    });
  };

  return (
    <VetAndUpcomingAppointments vetId={vetId} active="manageAvailability">
      <Container maxWidth="xl" className="scroll-90vh">
        <Grid container className="ph10 pv20">
          {checkTypeIncluded("Physical") && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  className="flex-row-ali-cen mt10 cursor"
                  onClick={() => {
                    setPhyEditEnabled(
                      vetPhyAvailability?.length === 0 ? true : false
                    );
                    setPhyOpen(!phyOpen);
                  }}
                >
                  {vetPhyAvailability?.length > 0 ? (
                    <div className="mr5 mlMin5 flex-row-ali-cen">
                      {phyOpen ? (
                        <KeyboardArrowDownIcon
                          sx={{ color: AppColors.appPrimary }}
                        />
                      ) : (
                        <KeyboardArrowRightIcon
                          sx={{ color: AppColors.appPrimary }}
                        />
                      )}
                    </div>
                  ) : null}

                  <Typography className="txt-semi-bold black fs14">
                    Physical Consulting Hours
                  </Typography>
                  {vetPhyAvailability?.length === 0 && (
                    <img
                      src={require("../../../assets/images/png/PlusIcon.png")}
                      alt=""
                      className="h20 cursor ml10"
                      onClick={() => {
                        setPhyEditEnabled(
                          vetPhyAvailability?.length === 0 ? true : false
                        );
                        setPhyOpen(!phyOpen);
                      }}
                    />
                  )}
                </div>
              </Grid>
              {vetPhyAvailability?.length === 0 && !phyOpen && (
                <div className="no-rec h100">No records available</div>
              )}
              {phyOpen ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="flex-row-ali-cen">
                      <Typography className="txt-regular fs14 black">
                        Use Clinic Schedule
                      </Typography>
                      <div className="ml10 flex-center">
                        <CustomSwitch
                          value={clinicSchduleEnabled}
                          onChange={() =>
                            setClinicSchduleEnabled(!clinicSchduleEnabled)
                          }
                          greenToGray
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="flex-row-ali-cen mt20">
                      <div className="w5Per"></div>
                      <div className="w15Per">
                        <Typography className="font-bold fs14 blue-color">
                          Day
                        </Typography>
                      </div>
                      <div className="w15Per">
                        <Typography className="font-bold fs14 blue-color">
                          Start Time
                        </Typography>
                      </div>
                      <div className="w15Per">
                        <Typography className="font-bold fs14 blue-color">
                          End Time
                        </Typography>
                      </div>
                      <div className="w5Per">
                        <CustomButton
                          text={
                            phyEditEnabled || clinicSchduleEnabled
                              ? "Save"
                              : "Edit"
                          }
                          smallBtn
                          onClick={() => {
                            if (phyEditEnabled || clinicSchduleEnabled) {
                              handleUpdateSlot("physical");
                            } else {
                              setPhyEditEnabled(!phyEditEnabled);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="box-hor-split mt5" />
                  </Grid>

                  {clinicSchduleEnabled
                    ? clinicHourList?.map((itm, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          key={itm?.day + index}
                        >
                          <div className="flex-row mv5">
                            <div className="w5Per" />
                            <div className="w15Per h25 align-center-wh">
                              <Typography className="txt-regular fs14 blue-color capitalize">
                                {itm?.day}
                              </Typography>
                            </div>
                            <div className="flex-coumn w60Per">
                              {itm?.slots?.map((slt, ind) => {
                                return (
                                  <div
                                    className="flex-row-ali-cen h25"
                                    key={slt?.startTime + "time" + ind}
                                  >
                                    <div className="w25Per pr10">
                                      <Typography className="txt-regular fs14 black">
                                        {itm?.isSelected
                                          ? moment(slt?.startTime).format(
                                              "HH:mm"
                                            )
                                          : ""}
                                      </Typography>
                                    </div>
                                    <div className="w25Per pr10">
                                      <Typography className="txt-regular fs14 black">
                                        {itm?.isSelected
                                          ? moment(slt?.endTime).format("HH:mm")
                                          : ""}
                                      </Typography>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="box-hor-split w50Per" />
                        </Grid>
                      ))
                    : phyEditEnabled
                    ? phyDayValues?.map((pd, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          key={pd?.day + i}
                        >
                          <div className="flex-row">
                            <div className="w5Per pl10">
                              <Checkbox
                                checked={pd?.isSelected}
                                onChange={(e) =>
                                  handlePhysicalDaySelect(i, !pd?.isSelected)
                                }
                              />
                            </div>
                            <div className="w15Per mt10">
                              <Typography className="txt-regular fs14 blue-color">
                                {pd?.day}
                              </Typography>
                            </div>

                            <div className="flex-coumn">
                              {pd?.slots?.map((slt, ind) => (
                                <div
                                  className="flex-row-ali-cen mv3"
                                  key={slt?.startTime + "time" + ind}
                                >
                                  <div className="w25Per pr10">
                                    <CustomTextField
                                      name="startTime"
                                      fullWidth
                                      mobileTime
                                      handleChange={(e) =>
                                        handleChangeDateValue(
                                          "startTime",
                                          e?.target?.value,
                                          i,
                                          ind
                                        )
                                      }
                                      value={slt?.startTime}
                                      is12HourFomat={false}
                                    />
                                  </div>
                                  <div className="w25Per pr10">
                                    <CustomTextField
                                      name="endTime"
                                      fullWidth
                                      mobileTime
                                      handleChange={(e) =>
                                        handleChangeDateValue(
                                          "endTime",
                                          e?.target?.value,
                                          i,
                                          ind
                                        )
                                      }
                                      value={slt?.endTime}
                                      is12HourFomat={false}
                                    />
                                  </div>
                                  <div className="w10Per">
                                    <div className="flex-row">
                                      {pd?.slots?.length > 1 ? (
                                        <img
                                          src={require("../../../assets/images/png/minus.png")}
                                          alt=""
                                          className="h20 cursor"
                                          onClick={() =>
                                            handleDeleteDateRow(i, ind)
                                          }
                                        />
                                      ) : (
                                        <div className="h20 w20px" />
                                      )}
                                      {ind === 0 && (
                                        <img
                                          src={require("../../../assets/images/png/PlusIcon.png")}
                                          alt=""
                                          className="h20 cursor ml10"
                                          onClick={() => handleAddDateRow(i)}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="box-hor-split w60Per" />
                        </Grid>
                      ))
                    : phyDayValues?.map((pd, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          key={pd?.day + i}
                        >
                          <div className="flex-row mv5">
                            <div className="w5Per pl10" />
                            <div className="w15Per h25 align-center-wh">
                              <Typography className="txt-regular fs14 blue-color">
                                {pd?.day}
                              </Typography>
                            </div>
                            <div className="flex-coumn w60Per">
                              {pd?.slots?.map((slt, ind) => (
                                <div
                                  className="flex-row-ali-cen h25"
                                  key={slt?.startTime + "time" + ind}
                                >
                                  <div className="w25Per pr10">
                                    {pd?.isSelected ? (
                                      <Typography className="txt-regular fs14 black">
                                        {moment(slt?.startTime).format("HH:mm")}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="w25Per pr10">
                                    {pd?.isSelected ? (
                                      <Typography className="txt-regular fs14 black">
                                        {moment(slt?.endTime).format("HH:mm")}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="box-hor-split w60Per" />
                        </Grid>
                      ))}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="flex-row-ali-cen mt10">
                      <Typography className="txt-semi-bold black fs14">
                        Slot Duration:
                      </Typography>
                      <div className="ml10 w15Per">
                        <CustomTextField
                          name="physical"
                          fullWidth
                          value={durationValues?.physical}
                          handleChange={handleChangesDurationValues}
                          endIcon
                          inputIcon="Mins"
                          disabled={!phyEditEnabled && !clinicSchduleEnabled}
                        />
                      </div>
                    </div>
                  </Grid>
                  {phyNoSlotErr && (
                    <FormHelperText error>
                      Please add atleast 1 record
                    </FormHelperText>
                  )}
                  {phyTimeErr && (
                    <FormHelperText error>
                      Please provide valid time. End Time should be greater than
                      the Start Time
                    </FormHelperText>
                  )}
                </>
              ) : null}
            </>
          )}

          {checkTypeIncluded("Virtual") && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  className={`flex-row-ali-cen cursor ${
                    checkTypeIncluded("Physical") ? "mt30" : ""
                  }`}
                  onClick={() => {
                    setVirEditEnabled(
                      vetVirAvailability?.length === 0 ? true : false
                    );
                    setVirOpen(!virOpen);
                  }}
                >
                  {vetVirAvailability?.length > 0 ? (
                    <div className="mr5 mlMin5 flex-row-ali-cen">
                      {virOpen ? (
                        <KeyboardArrowDownIcon
                          sx={{ color: AppColors.appPrimary }}
                        />
                      ) : (
                        <KeyboardArrowRightIcon
                          sx={{ color: AppColors.appPrimary }}
                        />
                      )}
                    </div>
                  ) : null}
                  <Typography className="txt-semi-bold black fs14">
                    Virtual Consulting Hours
                  </Typography>
                  {vetVirAvailability?.length === 0 && (
                    <img
                      src={require("../../../assets/images/png/PlusIcon.png")}
                      alt=""
                      className="h20 cursor ml10"
                      onClick={() => {
                        setVirEditEnabled(
                          vetVirAvailability?.length === 0 ? true : false
                        );
                        setVirOpen(!virOpen);
                      }}
                    />
                  )}
                </div>
              </Grid>
              {vetVirAvailability?.length === 0 && !virOpen && (
                <div className="no-rec h100">No records available</div>
              )}

              {virOpen ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="flex-row-ali-cen mt20">
                      <div className="w5Per"></div>
                      <div className="w15Per">
                        <Typography className="font-bold fs14 blue-color">
                          Day
                        </Typography>
                      </div>
                      <div className="w15Per">
                        <Typography className="font-bold fs14 blue-color">
                          Start Time
                        </Typography>
                      </div>
                      <div className="w15Per">
                        <Typography className="font-bold fs14 blue-color">
                          End Time
                        </Typography>
                      </div>
                      <div className="w5Per">
                        <CustomButton
                          text={virEditEnabled ? "Save" : "Edit"}
                          smallBtn
                          onClick={() => {
                            if (virEditEnabled) {
                              handleUpdateSlot("virtual");
                            } else {
                              setVirEditEnabled(!virEditEnabled);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="box-hor-split mt5" />
                  </Grid>

                  {virEditEnabled
                    ? virDayValues?.map((pd, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          key={pd?.day + i}
                        >
                          <div className="flex-row">
                            <div className="w5Per pl10">
                              <Checkbox
                                checked={pd?.isSelected}
                                onChange={(e) =>
                                  handleVirtualDaySelect(i, !pd?.isSelected)
                                }
                              />
                            </div>
                            <div className="w15Per mt10">
                              <Typography className="txt-regular fs14 blue-color">
                                {pd?.day}
                              </Typography>
                            </div>

                            <div className="flex-coumn">
                              {pd?.slots?.map((slt, ind) => (
                                <div
                                  className="flex-row-ali-cen mv3"
                                  key={slt?.startTime + "time" + ind}
                                >
                                  <div className="w25Per pr10">
                                    <CustomTextField
                                      name="startTime"
                                      fullWidth
                                      mobileTime
                                      handleChange={(e) =>
                                        handleVirChangeDateValue(
                                          "startTime",
                                          e?.target?.value,
                                          i,
                                          ind
                                        )
                                      }
                                      value={slt?.startTime}
                                      is12HourFomat={false}
                                    />
                                  </div>
                                  <div className="w25Per pr10">
                                    <CustomTextField
                                      name="endTime"
                                      fullWidth
                                      mobileTime
                                      handleChange={(e) =>
                                        handleVirChangeDateValue(
                                          "endTime",
                                          e?.target?.value,
                                          i,
                                          ind
                                        )
                                      }
                                      value={slt?.endTime}
                                      is12HourFomat={false}
                                    />
                                  </div>
                                  <div className="w10Per">
                                    <div className="flex-row">
                                      {pd?.slots?.length > 1 ? (
                                        <img
                                          src={require("../../../assets/images/png/minus.png")}
                                          alt=""
                                          className="h20 cursor"
                                          onClick={() =>
                                            handleVirDeleteDateRow(i, ind)
                                          }
                                        />
                                      ) : (
                                        <div className="h20 w20px" />
                                      )}
                                      {ind === 0 && (
                                        <img
                                          src={require("../../../assets/images/png/PlusIcon.png")}
                                          alt=""
                                          className="h20 cursor ml10"
                                          onClick={() => handleVirAddDateRow(i)}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="box-hor-split w60Per" />
                        </Grid>
                      ))
                    : virDayValues?.map((pd, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          key={pd?.day + i}
                        >
                          <div className="flex-row mv5">
                            <div className="w5Per pl10" />
                            <div className="w15Per h25 align-center-wh">
                              <Typography className="txt-regular fs14 blue-color">
                                {pd?.day}
                              </Typography>
                            </div>
                            <div className="flex-coumn w60Per">
                              {pd?.slots?.map((slt, ind) => (
                                <div
                                  className="flex-row-ali-cen h25"
                                  key={slt?.startTime + "time" + ind}
                                >
                                  <div className="w25Per pr10">
                                    {pd?.isSelected ? (
                                      <Typography className="txt-regular fs14 black">
                                        {moment(slt?.startTime).format("HH:mm")}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="w25Per pr10">
                                    {pd?.isSelected ? (
                                      <Typography className="txt-regular fs14 black">
                                        {moment(slt?.endTime).format("HH:mm")}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="box-hor-split w60Per" />
                        </Grid>
                      ))}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="flex-row-ali-cen mt10">
                      <Typography className="txt-semi-bold black fs14">
                        Slot Duration:
                      </Typography>
                      <div className="ml10 w15Per">
                        <CustomTextField
                          name="virtual"
                          fullWidth
                          value={durationValues?.virtual}
                          handleChange={handleChangesDurationValues}
                          endIcon
                          inputIcon="Mins"
                          disabled={!virEditEnabled}
                        />
                      </div>
                    </div>
                  </Grid>
                  {virNoSlotErr && (
                    <FormHelperText error>
                      Please add atleast 1 record
                    </FormHelperText>
                  )}
                  {virTimeErr && (
                    <FormHelperText error>
                      Please provide valid time. End Time should be greater than
                      the Start Time
                    </FormHelperText>
                  )}
                </>
              ) : null}
            </>
          )}

          {/* Holiday begins */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div
              className={`flex-row
              ${
                checkTypeIncluded("Virtual") || checkTypeIncluded("Physical")
                  ? "mt30"
                  : ""
              }`}
            >
              <Typography className="txt-semi-bold fs14">
                Personal Exceptions
              </Typography>
              <img
                src={require("../../../assets/images/png/PlusIcon.png")}
                alt=""
                className="h20 cursor ml10"
                onClick={handleAddHoliday}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex-row mt20">
              <div className="w20Per ph5">
                <Typography className="font-bold fs14 blue-color">
                  Start Date
                </Typography>
              </div>
              <div className="w20Per ph5">
                <Typography className="font-bold fs14 blue-color">
                  End Date
                </Typography>
              </div>
              <div className="w30Per ph5">
                <Typography className="font-bold fs14 blue-color">
                  Remarks
                </Typography>
              </div>
            </div>
            <div className="box-hor-split mt5" />
          </Grid>

          {holidays?.length > 0 &&
            holidays?.map((hol, i) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                key={hol?.holliId}
              >
                <div className="flex-row-ali-cen p10">
                  <div className="w20Per">
                    <Typography className="txt-regular fs14 black">
                      {moment(hol?.date).format("DD MMMM")}
                    </Typography>
                  </div>
                  <div className="w20Per">
                    <Typography className="txt-regular fs14 black">
                      {moment(hol?.endDate).format("DD MMMM")}
                    </Typography>
                  </div>
                  <div className="w30Per">
                    <Typography className="txt-regular fs14 black">
                      {hol?.holliday}
                    </Typography>
                  </div>
                  <div className="w5Per flex-center">
                    <img
                      src={require("../../../assets/images/png/delete.png")}
                      alt=""
                      className="h30 cursor"
                      onClick={() => handleDelHoliday(hol?.holliId)}
                    />
                  </div>
                </div>
                <div className="box-hor-split w80Per" />
              </Grid>
            ))}

          {holiday?.length > 0 &&
            holiday?.map((hol) => (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="flex-row p10">
                  <div className="w20Per pr10">
                    <CustomTextField
                      name="startDate"
                      fullWidth
                      mobileDate
                      handleChange={handleChangeHoliday}
                      value={hol?.startDate}
                    />
                  </div>
                  <div className="w20Per pr10">
                    <CustomTextField
                      name="endDate"
                      fullWidth
                      mobileDate
                      handleChange={handleChangeHoliday}
                      value={hol?.endDate}
                      minDate={hol?.startDate}
                    />
                  </div>
                  <div className="w30Per pr10">
                    <CustomTextField
                      name="remark"
                      fullWidth
                      handleChange={handleChangeHoliday}
                      value={hol?.remark}
                      error={holidayError}
                      helperText={
                        holidayError ? "Remark is required field" : ""
                      }
                    />
                  </div>
                  <div className="w5Per flex-center h40">
                    <img
                      src={require("../../../assets/images/png/save.png")}
                      alt=""
                      className="h30 cursor"
                      onClick={handleSaveHoliday}
                    />
                  </div>
                </div>
                <div className="box-hor-split" />
                {dateErr && (
                  <FormHelperText error>
                    Holiday End date should be equal to or greater than start
                    date
                  </FormHelperText>
                )}
              </Grid>
            ))}
          {holiday?.length === 0 && (!holidays || holidays?.length === 0) && (
            <div className="no-rec h100">No records available</div>
          )}
          {/* Holiday ends */}
        </Grid>
      </Container>
    </VetAndUpcomingAppointments>
  );
};

export default VetManageAvailability;
