import { Grid, Typography } from "@mui/material";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPetMedicalHistory } from "../../../../redux/reducers/medicalHistorySlice";

const Overview = ({
  getPetData,
  isVideoCallEnabled,
  setVideoCallEnabled,
  setAllergyModOpen,
  appointment,
}) => {
  const dispatch = useDispatch();
  const medHisList = useSelector(
    (state) => state?.medicalHistory?.medicalHistoryByPet
  );
  const [preventiveList, setPreventiveList] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const reqDet = useSelector((state) => state?.pet?.complaintSummary?.data);
  const petDetails = useSelector((state) => state?.pet?.petDetails);
  const apntId = appointment?.appoinment?.appoimentId;
  const appDet = reqDet?.appointment;
  const petData = apntId ? reqDet?.pet : petDetails;
  const apnt = appointment?.appoinment;

  useEffect(() => {
    if (!getPetData?.[0]?.petId && !apnt?.petId) return;
    dispatch(getPetMedicalHistory(getPetData?.[0]?.petId ?? apnt?.petId));
  }, [getPetData?.[0]?.petId || apnt?.petId]);

  useEffect(() => {
    getRequiredList();
  }, [medHisList]);

  const getRequiredList = () => {
    const prevList = [];
    const medList = [];
    medHisList?.map((ml) => {
      if (ml?.prevetive?.length > 0) {
        ml?.prevetive?.filter((pre) => {
          if (pre?.status === "Completed") {
            if (!prevList?.find((pr) => pr?.status === "Completed")) {
              prevList?.push({
                ...pre?.preventiveDetails?.[0],
                status: pre?.status,
              });
            }
          }
          if (pre?.status === "Pending") {
            if (!prevList?.find((pr) => pr?.status === "Pending")) {
              prevList?.push({
                ...pre?.preventiveDetails?.[0],
                status: pre?.status,
              });
            }
          }
          if (pre?.status === "Overdue") {
            if (!prevList?.find((pr) => pr?.status === "Overdue")) {
              prevList?.push({
                ...pre?.preventiveDetails?.[0],
                status: pre?.status,
              });
            }
          }
        });
      }
      if (ml?.prescription?.length > 0) {
        ml?.prescription?.[0]?.prescriptionDetails?.filter((pd) => {
          if (medList?.length !== 3) {
            medList?.push({ ...pd, date: ml?.prescription?.[0]?.createdDate });
          }
        });
      }
    });
    setPreventiveList(prevList);
    setMedicationList(medList);
  };

  const myMeeting = async (element) => {
    const roomID = appDet?.videoCallId;
    const userID = appDet?.doctorId?.toString();
    const userName = appDet?.doctorName;
    const token = "faeb80d20908d605f3acbf67f8c25191";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      704271205,
      token,
      roomID,
      userID,
      userName
    );

    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.GroupCall,
      },
      onLeaveRoom: async () => {
        setVideoCallEnabled(false);
        localStorage.setItem(
          "videoCallPageReloaded",
          JSON.stringify({
            state: appDet,
          })
        );
        window.location.reload();
      },
      showLeavingView: false,
      showScreenSharingButton: false,
      maxUsers: 2,
      showPreJoinView: false,
      showTextChat: false,
      showRoomDetailsButton: false,
    });
  };

  return (
    <>
      {isVideoCallEnabled ? (
        <div ref={myMeeting} className="vid-cal-con" />
      ) : null}

      <Grid container xs={12} className="mv3 back-white pb100">
        {apntId && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex-row-ali-cen">
              <div className="flex-row-ali-cen ovr-head-back">
                <Typography className="txt-semi-bold fs14 balck ml10">
                  {appDet?.appoinmentStatus === "completed"
                    ? "Previous Appointment:"
                    : appDet?.appoinmentStatus !== "completed" &&
                      appDet?.appoinmentStatus !== "cancelled"
                    ? "Upcoming Appointment:"
                    : ""}
                </Typography>
                <div className="flex-center">
                  <Typography
                    className="fs14 ml5 "
                    style={{ fontWeight: "650" }}
                  >
                    {moment(appDet?.appoinmentDate).format("DD MMM YYYY")}
                  </Typography>
                </div>

                <div className="flex-center">
                  <div className="ml10 card-light-blue-back card-time">
                    <Typography className="txt-regular card-blue2 fs12">
                      {appDet?.appoimentTime}
                    </Typography>
                  </div>
                </div>
                <Typography
                  className={`font-medium fs10 ml10 card-time  ${
                    appDet?.appoinmentType === "Physical"
                      ? "card-bot-blue-back"
                      : "virtual-bg"
                  }  white-color
                flex-center`}
                >
                  {appDet?.appoinmentType}
                </Typography>
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="flex-row-ali-cen mv5">
            <div className="flex-row ovr-head-back">
              <img
                src={require("../../../../assets/images/png/allergyIcon.png")}
                alt=""
                className="img-wh20 ml10"
              />
              <Typography className="txt-semi-bold fs14 balck ml10">
                Allergies:
              </Typography>
              {(apntId || apnt?.petId) && petData?.allergies?.length > 0 ? (
                petData?.allergies?.map((al, i) => (
                  <Typography className="txt-semi-bold fs14 card-head-orange-color ml5">
                    {`${al?.allergies}${
                      i + 1 === petData?.allergies?.length ? "" : ","
                    }`}
                  </Typography>
                ))
              ) : (
                <Typography className="txt-semi-bold fs14 card-head-orange-color ml5">
                  None specified
                </Typography>
              )}
              <div className="flex1-end">
                <img
                  src={require("../../../../assets/images/png/edit-new.png")}
                  alt=""
                  className="img-wh20 ml10 cursor"
                  onClick={() => setAllergyModOpen(true)}
                />
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="font-bold mt20">Compliants Summary</Typography>
        </Grid>
        {(apntId || apnt?.petId === getPetData?.[0]?.petId) &&
        getPetData?.length > 0 ? (
          getPetData?.map((problem, i) => (
            <div key={i}>
              <Typography className="font-bold gray7 fs12 mt10 capitalize">
                Latest Diagnoses: {problem.problemType}
              </Typography>

              {problem?.problems?.length > 0 ? (
                <>
                  <Typography className="font-medium black fs12 mt10">
                    Symptoms:
                  </Typography>

                  <div className="flex-row mt10">
                    {problem?.problems?.map((prb, j) => (
                      <Typography
                        className={`font-medium fs10 card-time blu-back white-color ${
                          j !== 0 ? "ml5" : ""
                        }`}
                      >
                        {prb}
                      </Typography>
                    ))}
                  </div>
                </>
              ) : null}

              <Grid container spacing={2} className="mt10">
                {problem?.image1 && (
                  <Grid item xs={3}>
                    <img src={problem?.image1} alt="" className="img-view" />
                  </Grid>
                )}
                {problem?.image2 && (
                  <Grid item xs={3}>
                    <img src={problem?.image2} alt="" className="img-view" />
                  </Grid>
                )}
                {problem?.images && (
                  <Grid item xs={3}>
                    <img src={problem?.images} alt="" className="img-view" />
                  </Grid>
                )}
              </Grid>
              {problem?.numberOfDays > 0 ? (
                <Typography className="txt-regular black fs12 mt10">
                  How long the issue has been there: {problem?.numberOfDays}{" "}
                  days
                </Typography>
              ) : null}
            </div>
          ))
        ) : (
          <div className="no-rec h50">No records available</div>
        )}

        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className="font-bold fs14">Summary</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt10">
          <div className="flex-row-between">
            <Typography className="txt-semi-bold fs12 gray7">
              Last visited: 05-May-2024
            </Typography>
            <Typography className="txt-semi-bold fs12 gray7">
              Latest Diagnoses: Ear Infection, Kennel Cough
            </Typography>
            <Typography className="txt-semi-bold fs12 card-blue2">
              View Summary
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt10">
          <Typography className="txt-regular fs12 gray11">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt20">
          <Typography className="font-bold fs14">Preventive History</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt10">
          <div className="flex-row-ali-cen">
            {(apntId || apnt?.petId === getPetData?.[0]?.petId) &&
            preventiveList?.length > 0 ? (
              preventiveList?.map((pl, i) => (
                <>
                  <div className="w30Per">
                    <div className="flex-column">
                      <Typography className="txt-semi-bold gray7 fs12 mt10">
                        {pl?.type === "vaccination"
                          ? "Vaccination"
                          : pl?.type === "fleaTreatment"
                          ? "Flea treatment"
                          : "Deworming"}
                      </Typography>
                      <Typography className="txt-semi-bold black2 fs12 mt10">
                        {pl?.name}
                      </Typography>
                      <div className="flex-row-ali-cen mt10">
                        <div className="gray-dot2" />
                        <Typography className="txt-semi-bold fs10 ml5 orange2">
                          Date: {moment(pl?.date).format("DD MMM YYYY")}
                        </Typography>
                      </div>
                    </div>
                    <Typography
                      className={`txt-semi-bold fs12 mt10 ph10pv3 br5 width-fit-con ${
                        pl?.status === "Completed"
                          ? "green2"
                          : pl?.status === "Pending"
                          ? "orange3"
                          : "red3"
                      } ${
                        pl?.status === "Completed"
                          ? "green-back2"
                          : "orange-back"
                      }`}
                    >
                      {pl?.status}
                    </Typography>
                  </div>

                  {preventiveList?.length !== i + 1 ? (
                    <div className="w5Per">
                      <div className="flex-center">
                        <div className="ver-bar-h30 h100" />
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            ) : (
              <div className="no-rec h50">No records available</div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt20">
          <Typography className="font-bold fs14">Medication History</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="flex-row-ali-cen">
            {(apntId || apnt?.petId === getPetData?.[0]?.petId) &&
            medicationList?.length > 0 ? (
              medicationList?.map((ml, i) => (
                <>
                  <div className="w30Per">
                    <div className="flex-column">
                      <Typography className="txt-semi-bold gray7 fs12 mt10">
                        {ml?.name ?? ""}
                      </Typography>
                      <div className="flex-row-ali-cen mt10">
                        <div className="gray-dot2" />
                        <Typography className="txt-semi-bold fs12 ml5 black2">
                          Prescribed date{" "}
                          {moment(ml?.date).format("DD MMM YYYY")}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  {medicationList?.length !== i + 1 ? (
                    <div className="w5Per">
                      <div className="flex-center">
                        <div className="ver-bar-h30 h50" />
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            ) : (
              <div className="no-rec h50">No records available</div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
