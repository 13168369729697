import { Grid } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import ClinicalNotes from "./ClinicalNotes";
import Diagnostics from "./Diagnostics";
import Prescription from "./Prescription";
import Preventive from "./Preventive";

const PatientWorkbook = ({ appointment, setScrollable }) => {
  const [activeTab, setActiveTab] = useState("ClinicNotes");

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="mv3 bg-white"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="back-white">
              <div className="flex-row">
                <div className="w15Per">
                  <CustomButton
                    text="Clinical Notes"
                    onClick={() => setActiveTab("ClinicNotes")}
                    tabOutlined
                    yellowBtn={activeTab === "ClinicNotes"}
                  />
                </div>
                <div className="w5Per">
                  <div className="flex-center">
                    <div className="ver-bar-h30" />
                  </div>
                </div>
                <div className="w15Per">
                  <CustomButton
                    text="Preventive"
                    onClick={() => setActiveTab("Preventive")}
                    tabOutlined
                    yellowBtn={activeTab === "Preventive"}
                  />
                </div>
                <div className="w5Per">
                  <div className="flex-center">
                    <div className="ver-bar-h30" />
                  </div>
                </div>
                <div className="w15Per">
                  <CustomButton
                    text="Prescription"
                    onClick={() => setActiveTab("Prescription")}
                    tabOutlined
                    yellowBtn={activeTab === "Prescription"}
                  />
                </div>
                <div className="w5Per">
                  <div className="flex-center">
                    <div className="ver-bar-h30" />
                  </div>
                </div>
                <div className="w15Per">
                  <CustomButton
                    text="Diagnostics"
                    onClick={() => setActiveTab("Diagnostics")}
                    tabOutlined
                    yellowBtn={activeTab === "Diagnostics"}
                  />
                </div>
              </div>
            </div>
          </Grid>
          {activeTab === "ClinicNotes" ? (
            <ClinicalNotes appointment={appointment} />
          ) : null}
          {activeTab === "Preventive" ? (
            <Preventive setScrollable={setScrollable} />
          ) : null}
          {activeTab === "Prescription" ? (
            <Prescription setScrollable={setScrollable} />
          ) : null}
          {activeTab === "Diagnostics" ? (
            <Diagnostics setScrollable={setScrollable} />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default PatientWorkbook;
