export const ProblemTypes = {
  Ears: "Ear",
  Emergency: "Emergency",
  Eyes: "Eye",
  Gastrointestinal: "Gastrointestinal",
  General: "General",
  Mobility: "Mobility",
  Mouth: "Mouth",
  Respiratory: "Respiratory",
  renalUrinary: "Urinary",
  Skin: "Skin & Coat",
  Others: "Others",
};

export const symptomsTypes = {
  notEating: "Not eating",
  vomitting: "Vomiting",
  looseStools: "Loose stools",
  constipation: "Constipation",
  worms: "Worms",
  indiscriminateEating: "Indiscriminate eating",
  draggingBottom: "Dragging bottom",

  frequentUrination: "Frequent urination",
  incontinence: "Incontinence",
  painful: "Painful",
  straining: "Straining",
  dribbling: "Dribbling",
  kidneyFailure: "Kidney Failure",
  others: "Other",
  dischargePenis: "Discharge - Penis",
  bleedingPenis: "Bleeding - Penis",
  swellingPenis: "Swelling/growth - Penis",
  dischargeVulva: "Discharge - Vulva",
  bleedingVulva: "Bleeding - Vulva",
  swellingVulva: "Swelling/growth - Vulva",

  weightLoss: "Weight loss",
  weightGain: "Weight gain",
  lethargy: "Lethargy",
  motionSickness: "Motion sickness/Travel",
  excessThirst: "Excess Thirst",
  shivering: "Shivering",
  pregnancy: "Pregenancy",
  other: "Other",
};
