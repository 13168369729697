import { Card, CardMedia, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import editNew from "../../../../assets/images/png/edit-new.png";
import CustomButton from "../../../../components/CustomButton";
import { postClinicalNotes } from "../../../../redux/reducers/clinicSlice";
import { hideLoader, showLoader } from "../../../../redux/reducers/loaderSlice";
import { getPetsCompliantSummary } from "../../../../redux/reducers/petSlice";
import { showSnackBar } from "../../../../redux/reducers/snackSlice";
import { notes } from "../../../../util/server";
import CustomTextField from "../../../../components/CustomTextField";
import { AppColors } from "../../../../util/AppColors";
import { CardCss } from "../../../../util/object";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import api from "../../../../redux/actions/api";

// Set the app element for accessibility purposes
Modal.setAppElement("#root");

const ClinicalNotes = ({ appointment }) => {
  const dispatch = useDispatch();
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordData, setRecordData] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [modVisible, setModVisible] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const [petDetails, setPetDetails] = useState(null);
  const recorder = useAudioRecorder();
  const clinicNotes = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.clinicalNotes
  );
  const [searchValue, setSearchValue] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);
  const [preModVsble, setPreModVsble] = useState(false);

  const [sections, setSections] = useState({
    KeyIdentification: "",
    Subjective: "",
    Objective: "",
    Assessment: "",
    Plan: "",
    Conclusion: "",
    // Summary: "",
  });

  useEffect(() => {
    getPetDetails();
    const checkPermissions = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        // alert('Accepted the permissions');
        // setHavePermissions(true);
        // Do something with the stream if needed
      } catch (err) {
        // setHavePermissions(false);
        console.log(`${err.name} : ${err.message}`);
      }
    };
    checkPermissions();
    // const extractSections = (note) => {
    //   const subjectiveMatch = note?.match(
    //     /(\*\*Subjective:\*\*\n)([\s\S]*?)(?=\*\*Objective:\*\*)/
    //   );
    //   const objectiveMatch = note?.match(
    //     /(\*\*Objective:\*\*\n)([\s\S]*?)(?=\*\*Assessment:\*\*)/
    //   );
    //   const assessmentMatch = note?.match(
    //     /(\*\*Assessment:\*\*\n)([\s\S]*?)(?=\*\*Plan:\*\*)/
    //   );
    //   const planMatch = note?.match(
    //     /(\*\*Plan:\*\*\n)([\s\S]*?)(?=\*\*Conclusion:\*\*)/
    //   );
    //   const conclusionMatch = note?.match(
    //     /(\*\*Conclusion:\*\*\n)([\s\S]*?)(?=\*\*Additional Information\*\*)/
    //   );

    //   setSections({
    //     KeyIdentification: getTagValue(note),
    //     Subjective: subjectiveMatch ? subjectiveMatch[2].trim() : "",
    //     Objective: objectiveMatch ? objectiveMatch[2].trim() : "",
    //     Assessment: assessmentMatch ? assessmentMatch[2].trim() : "",
    //     Plan: planMatch ? planMatch[2].trim() : "",
    //     Conclusion: conclusionMatch ? conclusionMatch[2].trim() : "",
    //   });
    // };

    // extractSections(recordData?.medical_note);

    setSections({
      KeyIdentification: recordData?.DifferentialDiagnosis,
      Subjective: recordData?.Subjective,
      Objective: recordData?.Objective,
      Assessment: recordData?.Assessment,
      Plan: recordData?.Plan,
      Conclusion: recordData?.Conclusion,
    });
  }, [recordData]);

  const getPetDetails = async () => {
    const res = await api({ contentType: true, auth: true }).get(
      `user/pet/${appointment?.appoinment?.petId}/details`
    );

    if (res?.data.status === 302) {
      setPetDetails(res?.data?.data);
    } else {
      setPetDetails(null);
    }
  };

  function getTagValue(note) {
    const tagRegex = /\*\*TAG:\*\* (.+)/;

    const match = note?.match(tagRegex);
    if (match && match[1]) {
      return match?.[1]?.trim();
    }

    return null;
  }

  const handleEditClick = (section) => {
    setEditingSection(section);
  };

  const handleInputChange = (event, section) => {
    setSections({
      ...sections,
      [section]: event?.target?.value,
    });
  };

  const addAudioElement = (blob) => {
    setAudioBlob(blob);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
  };

  const ApiCall = async () => {
    if (!audioBlob) return;
    setDataSaved(false);
    dispatch(showLoader());
    const str = Object.entries(petDetails)
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");
    // const str  = `"petName": "Bruno",
    //     "species": "",
    //     "breed": "Labrador Retriever",
    //     "age": "2024-05-16",
    //     "reproductiveStatus": "false",
    //     "gender": "male",
    //     "weight": "32",
    //     "appointmentDateTime": null,
    //     "veterinaryClinic": "Vetinstant"`
    const formData = new FormData();
    formData.append("audio_file", audioBlob, "recording.webm");
    formData.append("medical_history", str);
    try {
      const response = await axios.post(notes, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRecordData(response.data);
      dispatch(hideLoader());
      dispatch(
        showSnackBar({
          message: "Record created successfully!",
          type: "success",
        })
      );
      setModVisible(false);
    } catch (error) {
      console.error("Error uploading audio:", error);
      dispatch(hideLoader());
    }
  };

  const handleFinilize = () => {
    setEditingSection(null);
    const data = {
      // "clinical_keys": "String",
      // "summary": "String",
      subjective: sections?.Subjective,
      objective: sections?.Objective,
      assessment: sections?.Assessment,
      plan: sections?.Plan,
      conclusion: sections?.Conclusion,
      tag: sections?.KeyIdentification,
    };
    const appId = appointment?.appoinment?.appoimentId;
    const metaData = { appId, data };
    dispatch(postClinicalNotes(metaData)).then((res) => {
      if (res?.payload) {
        setDataSaved(true);
        setModVisible(false);
        setAudioBlob(null);
        setRecordData(null);
        dispatch(getPetsCompliantSummary(appId));
        dispatch(
          showSnackBar({
            message: "Changes updated successfully!",
            type: "success",
          })
        );
      }
    });
  };

  const handleClosePrevMod = () => {
    setSelectedNote(null);
    setPreModVsble(false);
  };

  const handlePreNote = (note) => {
    setSelectedNote(note);
    setPreModVsble(true);
  };

  return (
    <>
      <div className="w100Per mt20 common-pb-50 scroll-80vh">
        {modVisible && (
          <div
            className="flex-column p15"
            style={{ backgroundColor: "#F7F7F7", borderRadius: "10px" }}
          >
            <div className="flex-row-between">
              <div className="heading-fw600 fs14 black">Transcription</div>
              <div>
                <div className="txt-regular fs12 text-align-right">
                  {moment(new Date()).format("DD MMM YYYY")}
                </div>
                <div className="txt-regular fs12 text-align-right">
                  {moment(new Date()).format("HH:mm")}
                </div>
              </div>
            </div>
            <div>
              <AudioRecorder
                recorder={recorder}
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                showVisualizer={true}
                downloadFileExtension="mp3"
              />
            </div>
          </div>
        )}
        {audioBlob && !recordData && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex-end mt20">
              <div className="">
                <CustomButton text="Generate SOAP" onClick={ApiCall} />
              </div>
            </div>
          </Grid>
        )}

        {recordData && !dataSaved ? (
          <>
            {[
              "Key Identification/Diagnosis",
              // "Summary",
              "Subjective",
              "Objective",
              "Assessment",
              "Plan",
              "Conclusion",
            ].map((section, i) =>
              i === 0 ? (
                <div key={section} className="mt20">
                  <div className="flex-row-ali-cen">
                    <div className="heading-fw600 fs14 black">{section}</div>
                    <Typography
                      className={`font-medium fs10 card-time blu-back white-color ml5`}
                    >
                      {sections?.KeyIdentification}
                    </Typography>
                  </div>
                </div>
              ) : (
                <div key={section} className="mt20">
                  <div className="flex-row-between-align-center">
                    <div className="heading-fw600 fs14 black">{section}</div>
                    {!dataSaved && (
                      <div
                        className="cursor"
                        onClick={() => handleEditClick(section)}
                      >
                        <img src={editNew} alt="edit" />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      border: "2px solid #E3E3EB",
                      borderRadius: "10px",
                    }}
                    className="text400 mt10 back-white"
                  >
                    {editingSection === section ? (
                      <TextField
                        fullWidth
                        value={sections?.[section]}
                        onChange={(event) => handleInputChange(event, section)}
                      />
                    ) : (
                      <div className="p15">{sections?.[section]}</div>
                    )}
                  </div>
                </div>
              )
            )}
            {!dataSaved && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="clinic-mod-btn-pos mt20">
                  {/* <div className="mr10" onClick={ApiCall}>
                <Typography className="red-color fs14 txt-semi-bold">
                  Regenerate
                </Typography>
              </div> */}
                  <div className="ml10">
                    <CustomButton
                      text="Save"
                      smallBtn
                      onClick={handleFinilize}
                    />
                  </div>
                </div>
              </Grid>
            )}
          </>
        ) : // clinicNotes?.[0] ? (
        //   <>
        //     {/* KeyIdentifier */}
        //     <div className="mt20">
        //       <div className="flex-row-ali-cen">
        //         <div className="heading-fw600 fs14 black">
        //           Key Identification/Diagnosis
        //         </div>
        //         {clinicNotes?.[0]?.tag && (
        //           <Typography className="font-medium fs10 card-time blu-back white-color ml10">
        //             {clinicNotes?.[0]?.tag}
        //           </Typography>
        //         )}
        //       </div>
        //     </div>
        //     {/* Summary */}
        //     {/* <div className="mt20">
        //     <div className="flex-row-between-align-center">
        //       <div className="heading-fw600 fs14 black">Summary</div>
        //     </div>
        //     <div

        //       className="text400 mt10 p15 back-white cli-not-box"
        //     >
        //       {clinicNotes?.summary}
        //     </div>
        //   </div> */}

        //     {/* subjective */}

        //     <div className="mt20">
        //       <div className="flex-row-between-align-center">
        //         <div className="heading-fw600 fs14 black">Subjective</div>
        //       </div>
        //       <div className="text400 mt10 p15 back-white cli-not-box">
        //         {clinicNotes?.[0]?.subjective}
        //       </div>
        //     </div>

        //     {/* objective*/}

        //     <div className="mt20">
        //       <div className="flex-row-between-align-center">
        //         <div className="heading-fw600 fs14 black">Objective</div>
        //       </div>
        //       <div className="text400 mt10 p15 back-white cli-not-box">
        //         {clinicNotes?.[0]?.objective}
        //       </div>
        //     </div>

        //     {/* assessment*/}

        //     <div className="mt20">
        //       <div className="flex-row-between-align-center">
        //         <div className="heading-fw600 fs14 black">Assessment</div>
        //       </div>
        //       <div className="text400 mt10 p15 back-white cli-not-box">
        //         {clinicNotes?.[0]?.assessment}
        //       </div>
        //     </div>

        //     {/* Plan*/}

        //     <div className="mt20">
        //       <div className="flex-row-between-align-center">
        //         <div className="heading-fw600 fs14 black">Plan</div>
        //       </div>
        //       <div className="text400 mt10 p15 back-white cli-not-box">
        //         {clinicNotes?.[0]?.plan}
        //       </div>
        //     </div>

        //     {/* conclusion*/}

        //     <div className="mt20">
        //       <div className="flex-row-between-align-center">
        //         <div className="heading-fw600 fs14 black">Conclusion</div>
        //       </div>
        //       <div className="text400 mt10 p15 back-white cli-not-box">
        //         {clinicNotes?.[0]?.conclusion}
        //       </div>
        //     </div>
        //   </>
        // ) :
        null}
        {!modVisible && (
          <>
            {!recordData && !clinicNotes && clinicNotes?.length === 0 && (
              <div className="no-rec">No records available</div>
            )}
            <div
              className={`flex-center mb20 ${
                (recordData || clinicNotes) && "mt30"
              }`}
            >
              {recordData ? null : (
                <div className="">
                  <CustomButton
                    text="Create SOAP Notes"
                    onClick={() => setModVisible(true)}
                    smallBtn
                  />
                </div>
              )}
            </div>
          </>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="ph20 pb100"
        >
          <div className="box-hor-split mv20" />
          <div className="font-bold fs14 mv20 blue-color">
            Clinical Notes History
          </div>
          <div className="flex-center">
            <div className="w75Per">
              <CustomTextField
                search
                placeholder="Search"
                fullWidth
                backgroundColor={AppColors.lightPink}
                value={searchValue}
                handleChange={(e) => setSearchValue(e?.target?.value)}
              />
            </div>
          </div>
          <div className="pb100 w100Per">
            {appointment?.appoinment?.appoimentId &&
            clinicNotes?.filter(
              (cn) =>
                cn?.appoinment?.doctorName
                  ?.toLowerCase()
                  ?.includes(searchValue.toLowerCase()) ||
                cn?.appoinment?.reason
                  ?.toLowerCase()
                  ?.includes(searchValue.toLowerCase())
            )?.length > 0 ? (
              clinicNotes
                ?.filter(
                  (cn) =>
                    cn?.appoinment?.doctorName
                      ?.toLowerCase()
                      ?.includes(searchValue.toLowerCase()) ||
                    cn?.appoinment?.reason
                      ?.toLowerCase()
                      ?.includes(searchValue.toLowerCase())
                )
                ?.map((cln, ind) => {
                  const apntmnt = cln?.appoinment;
                  return (
                    <div className="flex-row-ali-cen mv10" key={ind + "pres"}>
                      <div className="w20Per">
                        <div className="back-img">
                          <div className="flex-center h100">
                            <div className="flex-column flex-center">
                              <Typography className="black2 fs10 font-bold">
                                {moment(new Date(cln?.createdDate)).format(
                                  "DD MMM"
                                )}
                              </Typography>
                              <Typography className="black2 fs10 font-bold mt5">
                                {moment(new Date(cln?.createdDate)).format(
                                  "YYYY"
                                )}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w60Per">
                        <Card sx={CardCss} className="inner-cards h110">
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mt10"
                            >
                              <div className="card-top-color card-top-blue-color" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="ph10">
                                <div className="flex-row">
                                  <div className="w90Per">
                                    <div className="flex-start">
                                      <div className="flex-column w10Per">
                                        <div className="h50">
                                          {apntmnt?.doctorImage ? (
                                            <CardMedia
                                              image={apntmnt?.doctorImage}
                                              className="img-h40"
                                            />
                                          ) : (
                                            <div className="flex-center img-hw40 back-gray2">
                                              <Typography className="font-bold fs30 white-color capitalize">
                                                {apntmnt?.doctorName?.[0]}
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                        <div className="card-light-blue-back card-time flex-center">
                                          <Typography className="txt-regular card-blue2 fs12">
                                            {moment(
                                              new Date(cln?.createdDate)
                                            ).format("HH:mm")}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div className="flex-column ml15 jus-con-spa-bet">
                                        <div className="h50">
                                          <Typography className="font-bold fs14 black capitalize">
                                            Dr. {apntmnt?.doctorName}
                                          </Typography>
                                          <Typography className="gray7 fs14 font-medium mt5 capitalize">
                                            {apntmnt?.reason}
                                          </Typography>
                                        </div>
                                        <div className="card-con-blue-back card-time flex-center w100Px">
                                          <Typography className="txt-regular white-color fs12">
                                            {apntmnt?.appoinmentType}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w10Per">
                                    <div className="flex-center h90">
                                      <img
                                        src={require("../../../../assets/images/png/view.png")}
                                        alt=""
                                        className="img-hw40 cursor"
                                        onClick={() => handlePreNote(cln)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="no-rec">No records available</div>
            )}
            {/* {clinicNotes?.filter(
            (pr) =>
              pr?.doctorName
                ?.toLowerCase()
                ?.includes(searchValue.toLowerCase()) ||
              pr?.issue?.toLowerCase()?.includes(searchValue.toLowerCase())
          )?.length > 0 ? (
            Math.ceil(prescriptions?.totalRecords / itemsPerLoad) > 1 ? (
              <div className="flex-end">
                <Pagination
                  count={Math.ceil(prescriptions?.totalRecords / itemsPerLoad)}
                  variant="outlined"
                  color="primary"
                  page={page}
                  onChange={handleChangePage}
                />
              </div>
            ) : null
          ) : null} */}
          </div>
        </Grid>
      </div>
      <CustomModal
        open={preModVsble}
        onClose={handleClosePrevMod}
        header=""
        modal
        modalWidth={70}
      >
        <div className="scroll-70vh w100Per">
          {selectedNote ? (
            <>
              <div className="mt20">
                <div className="flex-row-ali-cen">
                  <div className="heading-fw600 fs14 black">
                    Key Identification/Diagnosis
                  </div>
                  {selectedNote?.tag && (
                    <Typography className="font-medium fs10 card-time blu-back white-color ml10">
                      {selectedNote?.tag}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="mt20">
                <div className="flex-row-between-align-center">
                  <div className="heading-fw600 fs14 black">Subjective</div>
                </div>
                <div className="text400 mt10 p15 back-white cli-not-box">
                  {selectedNote?.subjective}
                </div>
              </div>
              <div className="mt20">
                <div className="flex-row-between-align-center">
                  <div className="heading-fw600 fs14 black">Objective</div>
                </div>
                <div className="text400 mt10 p15 back-white cli-not-box">
                  {selectedNote?.objective}
                </div>
              </div>
              <div className="mt20">
                <div className="flex-row-between-align-center">
                  <div className="heading-fw600 fs14 black">Assessment</div>
                </div>
                <div className="text400 mt10 p15 back-white cli-not-box">
                  {selectedNote?.assessment}
                </div>
              </div>
              <div className="mt20">
                <div className="flex-row-between-align-center">
                  <div className="heading-fw600 fs14 black">Plan</div>
                </div>
                <div className="text400 mt10 p15 back-white cli-not-box">
                  {selectedNote?.plan}
                </div>
              </div>
              <div className="mt20">
                <div className="flex-row-between-align-center">
                  <div className="heading-fw600 fs14 black">Conclusion</div>
                </div>
                <div className="text400 mt10 p15 back-white cli-not-box">
                  {selectedNote?.conclusion}
                </div>
              </div>
            </>
          ) : (
            <div className="no-rec h100">No records available</div>
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default ClinicalNotes;
