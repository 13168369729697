import axios from "axios";
import { baseURL } from './../../util/server';

function headerConfig(contentType, auth, formData) {
  const header = {};

  if (contentType && typeof contentType === "string") {
    header["Content-Type"] = contentType;
  } else {
    header["Content-Type"] = "application/json";
  }

  if (formData === true) {
    header["Content-Type"] = "multipart/form-data";
  }

  if (auth === true) {
    const token = localStorage.getItem("accessToken");
    header.Authorization = `Bearer ${token}`;
  }

  return header;
}

export default ({ contentType, auth, formData }) =>
  axios.create({
    baseURL,
    headers: headerConfig(contentType, auth, formData),
  });
